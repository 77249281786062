var EventStatus = [
  { label: 'En attente de validation', value: 'A1', color: '#ff6600' },
  { label: 'Nouveau Live Shopping', value: 'C1', color: '#00a108' },
  { label: 'Live Shopping confirmé', value: 'C2', color: '#00a108' },
  { label: 'Rendez-vous confirmé', value: 'C3', color: '#00a108' },
  { label: 'Live SHopping finalisé', value: 'F1', color: '#ff6600' },
  { label: 'Live Shopping annulé', value: 'Z1', color: '#666' }, //RDV annulé par le user
  { label: 'Live Shopping annulé', value: 'Z2', color: '#666' }, //RDV annulé par le client
  { label: 'Live Shopping refusé', value: 'Z3', color: '#666' }, //RDV refusé par le client
  { label: 'Rendez-vous annulé', value: 'Z9', color: '#666' }, //le client ne s'est pas présenté
  { label: 'Problème signalé', value: 'X1', color: 'red' },
  { label: 'loading', value: '00', color: '#666' },
];

export default EventStatus;
