import React, { Component, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { graphql, compose, withApollo } from 'react-apollo';

import { Link } from 'react-router-dom';

import QueryGetCalendar from '../graphql/QueryGetCalendar';
import QueryAllCalendars from '../graphql/QueryAllCalendars';
import MutationCreateCalendar from '../graphql/MutationCreateCalendar';
import MutationDeleteCalendar from '../graphql/MutationDeleteCalendar';
import MutationUpdateCalendar from '../graphql/MutationUpdateCalendar';
import QueryActiveVendorEvents from '../graphql/QueryActiveVendorEvents';
import QueryAllActiveEvents from '../graphql/QueryAllActiveEvents';

import QueryGetMember from '../graphql/QueryGetMember';
import MutationUpdateMemberStatus from '../graphql/MutationUpdateMemberStatus';

import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import { RiTimeLine } from 'react-icons/ri';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Divider, Dialog, DialogContent, Button, DialogTitle, DialogActions, Accordion } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/fr';
moment.locale('fr');

function getUser() {
  let user = Auth.user.attributes['email'];
  return user;
}

const timezone = moment.tz.guess();
const offSet = parseInt(moment.tz(timezone).format('Z'));

const START_HOUR = 9;
const END_HOUR = 20;
const NB_WEEK_DAYS = 7;
const SLOT_DURATION = 20;

const styles = theme => ({
  time_slot: {
    minHeight: 60,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    height: '100%',
    position: 'relative',
    borderRadius: 3,
    padding: '10px 3px',
    background: '#d6ebff',
    margin: '0px 5px 7px 0px',
    fontWeight: '600',
    color: '#152638',
    fontFamily: 'ProximaNova,sans-serif',
    '&:hover': {
      background: '#054875',
      color: '#fff',
    },
  },
  disabled: {
    background: '#e2e2e2',
  },
  header_day: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    position: 'relative',
    padding: '10px 3px',
    margin: '0 5px 0 0',
    fontWeight: '600',
    fontSize: 20,
    textTransform: 'capitalize',
    color: '#152638',
    fontFamily: 'ProximaNova,sans-serif',
  },
  header_date: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    position: 'relative',
    padding: '10px 3px',
    margin: '0 5px 15px 0',
    //fontWeight:'600',
    fontSize: 18,
    textTransform: 'capitalize',
    color: '#152638',
    fontFamily: 'ProximaNova,sans-serif',
  },
});

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      week_index: 0,
      allTeam: true,
      loading: true,
      events: [],
      calendars: [],
      arraySlot: [],
      selectedSlot: 'undefined',
      needUpdate: false,
      showModal: false,
    };
    this.initialState = { ...this.state };
  }

  handleChange(field, { target: { value } }) {
    const { member } = this.state;
    member[field] = value;
    this.setState({ member });
  }

  scrollTo = () =>
    window.scroll({
      top: this.myRef.current.offsetTop,
      left: 0,
      behavior: 'smooth',
    });

  componentDidMount = async () => {
    const { client } = this.props;
    const email = getUser();
    const member = await client.query({
      query: QueryGetMember,
      variables: { email: email },
      fetchPolicy: 'network-only',
    });
    this.setState({ member: member.data.getTeam });
    await this.getEvents(this.state.allTeam);
    await this.getCalendars(this.state.allTeam);
    this.freshCalendar(0);
  };

  setTeam = async allTeam => {
    await this.getEvents(allTeam);
    await this.getCalendars(allTeam);
    this.freshCalendar(this.state.week_index);
    this.setState({ allTeam: allTeam });
  };

  getEvents = async allTeam => {
    const { client } = this.props;
    const { member } = this.state;
    const TeamVar = { vendorID: member.vendorID };
    const MemberVar = { email: member.email };

    const result = await client.query({
      query: allTeam ? QueryActiveVendorEvents : QueryAllActiveEvents,
      variables: allTeam ? TeamVar : MemberVar,
      fetchPolicy: 'network-only',
    });
    this.setState({
      events: result.data.listEvents.items,
    });
  };

  getCalendars = async allTeam => {
    const { member, client } = this.props;

    const TeamVar = { vendorID: member.vendorID };
    const MemberVar = { email: member.email };

    const result = await client.query({
      query: allTeam ? QueryAllCalendars : QueryGetCalendar,
      variables: allTeam ? TeamVar : MemberVar,
      fetchPolicy: 'network-only',
    });
    this.setState({
      calendars: result.data.listCalendars.items,
    });
  };

  freshCalendar(week_index) {
    this.setState({ loading: true });
    const { events, calendars } = this.state;
    var INIT_DATE =
      week_index >= 0
        ? moment()
            .clone()
            .add(week_index, 'w')
            .format()
        : moment()
            .clone()
            .subtract(-week_index, 'w')
            .format();
    var WEEK_STARTS = moment(INIT_DATE)
      .startOf('week')
      .format();
    const START_INDEX = 1; //monday

    /* Week header */
    var arrayWeekHeader = [];
    for (let index = START_INDEX; index < NB_WEEK_DAYS + 1; ++index) {
      const date = moment(WEEK_STARTS)
        .add(index - 1, 'd')
        .format();
      arrayWeekHeader.push({
        idDay: index,
        title: moment.weekdays(index),
        date: date,
      });
    }
    this.setState({ arrayWeekHeader });

    /* initilisation du calendrier vide */
    let arrayWeekCal = [];

    for (let index = START_INDEX; index < NB_WEEK_DAYS + 1; ++index) {
      for (let hour = START_HOUR; hour < END_HOUR; ++hour) {
        const date = moment(WEEK_STARTS)
          .add(index - 1, 'd')
          .format();
        const full_date = moment(date)
          .add(hour, 'h')
          .format();
        arrayWeekCal.push({
          idDay: index,
          title: moment.weekdays(index),
          date: full_date,
          time: hour,
          available: false,
        });
      }
    }

    /* initialisation slot disponibilités */
    let arrayWeekSlots = [];
    {
      calendars &&
        calendars.forEach((row, index) => {
          var DAY = row.idDay;

          var row_date = moment(WEEK_STARTS)
            .add(DAY - 1, 'd')
            .format();
          var row_date_start = moment(row_date)
            .startOf('day')
            .format();

          var slot_start = 60 * (parseInt(row.start.slice(0, 2)) + offSet) + parseInt(row.start.slice(3, 5)); //converti en minutes le début de la plage
          var slot_end =
            60 * (parseInt(row.end.slice(0, 2)) + offSet) + parseInt(row.end.slice(3, 5)) - SLOT_DURATION + 1; //converti en minutes le fin de la plage

          let start_hour = moment(row_date_start)
            .add(slot_start, 'm')
            .format();
          let end_hour = moment(row_date_start)
            .add(slot_end, 'm')
            .format();

          const diff = moment.duration(moment(end_hour).diff(moment(start_hour)));
          var diff_minutes = parseInt(diff.asMinutes());

          for (let i = 0; i < diff_minutes; i += SLOT_DURATION) {
            var slot = moment(start_hour)
              .add(i, 'm')
              .format();
            var diff_ = moment.duration(moment(slot).diff(moment()));
            if (diff_ > 0) {
              arrayWeekSlots.push({
                idDay: DAY,
                title: row.day,
                date: slot,
                host: row.email,
                hour: moment(start_hour)
                  .add(i, 'm')
                  .format('HH'),
                time: moment(start_hour)
                  .add(i, 'm')
                  .format('HH:mm'),
                available: true,
              });
            }
          }
        });
    }

    /* Suppression des plages déjà occupés (events) */

    var arrayAvailable = arrayWeekSlots.filter(
      n => !events.some(n2 => n.host == n2.host && moment(n.date).format() == moment(n2.eventDate).format())
    );

    //console.log(JSON.stringify(res))

    /*  création array heures dispo (regroupement par plages d'une heure) */
    const arrayHoursAvailable = arrayAvailable.filter(
      (slot, index, self) => index === self.findIndex(t => t.hour === slot.hour && t.idDay === slot.idDay)
    );

    // console.log(arrayWeekSlots);
    // console.log(arrayAvailable);
    // console.log(arrayHoursAvailable);

    /*  Merge des plages avec les dispos  */

    // slower method console.time('Method #1') console.timeEnd('Method #1')
    //const output = arrayWeekCal.map(e => arrayHoursAvailable.some( f => moment(f.date).startOf('hour').format() == moment(e.date).startOf('hour').format()) ? ({ ...e, 'available' : arrayHoursAvailable.find(x => moment(x.date).startOf('hour').format() == moment(e.date).startOf('hour').format()).available }) : e);

    arrayWeekCal.map(function(slot) {
      arrayHoursAvailable.map(function(f) {
        if (
          moment(f.date)
            .startOf('hour')
            .format() ==
          moment(slot.date)
            .startOf('hour')
            .format()
        ) {
          slot.available = f.available;
        }
      });
      return arrayWeekCal;
    });

    //console.log(arrayWeekCal);

    this.setState({ arrayWeekCal });
    this.setState({ arrayAvailable });
    this.setState({ loading: false });
  }

  picSlot = date => {
    const { arrayAvailable } = this.state;
    var arraySlot = arrayAvailable.filter(
      x =>
        moment(x.date)
          .startOf('hour')
          .format() ==
        moment(date)
          .startOf('hour')
          .format()
    ); // if more than one slot open dialog to chose
    this.setState({ showModal: true });
    this.setState({ arraySlot });
    this.setState({ selectedSlot: arraySlot[0].date });
  };

  static defaultProps = {
    updateStatus: () => null,
  };

  handleChange = async (member, e) => {
    e.preventDefault();
    const { updateStatus } = this.props;
    const newstatus = {
      email: member.email,
      status: !member.status,
    };
    await updateStatus(newstatus);
    await this.refreshCalendar();
  };

  render() {
    const { showModal, arrayWeekCal, allTeam, arrayAvailable, arrayWeekHeader, week_index, arraySlot } = this.state;
    const { classes } = this.props;
    return (
      <div className="ui container raised very padded segment">
        <h1 className="pageTitle" style={{ display: 'block', padding: '30px' }}>
          Nouveau
          <br />
          Rendez-vous
          <br />
          Live Shopping.
        </h1>
        <div className="content">
          <div className="field eight wide">
            {this.state.loading ? (
              <div
                style={{
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                  alignContent: 'center',
                  padding: 100,
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      checked={allTeam}
                      onChange={() => {
                        this.setTeam(!allTeam);
                      }}
                      name="checked"
                      color="primary"
                    />
                  }
                  label={allTeam ? "Toutes les disponibilités de l'équipe" : 'Uniquement mes disponibilités'}
                  style={{ fontWeight: '600' }}
                />
                <div style={{ columns: 1 }}>
                  <div style={{ padding: '30px 0 0 0', fontWeight: '600', fontSize: 16, maxWidth: 380 }}>
                    Les prochaines disponibilités
                    {week_index == 0
                      ? ' cette semaine'
                      : ` de la semaine commençant le ${moment()
                          .add(week_index, 'w')
                          .startOf('week')
                          .format('dddd, Do MMMM')}`}{' '}
                    :{' '}
                  </div>
                  <div
                    className={classes.timezone}
                    style={{ alignItems: 'center', padding: '7px 0 20px 0', display: 'flex', color: '#666' }}
                  >
                    <RiTimeLine size={15} />
                    <div style={{ paddingLeft: 7, fontSize: 13 }}>
                      {timezone} {`(UTC${moment.tz(timezone).format('Z')})`}
                    </div>
                  </div>
                  {arrayAvailable &&
                    arrayAvailable
                      .slice(0, 5)
                      .sort((a, b) => a.date.localeCompare(b.date))
                      .map((slot, index) => {
                        return (
                          <Link
                            to={`/book/${moment(slot.date)
                              .tz(timezone)
                              .format()}`}
                            key={index}
                            className={classes.time_slot}
                            style={{ maxWidth: 350, textTransform: 'capitalize' }}
                          >
                            {moment(slot.date).format('dddd, Do MMMM à HH:mm')}
                          </Link>
                        );
                      })}
                  <Button
                    className={classes.time_slot}
                    style={{ maxWidth: 350, backgroundColor: '#054875', color: '#fff' }}
                    onClick={() => {
                      this.scrollTo();
                    }}
                  >
                    Voir toutes les disponibilités
                  </Button>
                </div>

                {
                  <div className="field eight wide" style={{ marginTop: 35 }} ref={this.myRef}>
                    <Button
                      onClick={() => {
                        var week_ = week_index - 1;
                        this.setState({ week_index: week_index - 1 });
                        this.freshCalendar(week_);
                      }}
                      style={{ color: '#ff6600', fontSize: 12, justifyContent: 'flex-start', padding: 0 }}
                    >
                      <IoIosArrowBack size={30} color="#ff6600" />
                    </Button>

                    <Button
                      onClick={() => {
                        var week_ = week_index + 1;
                        this.setState({ week_index: week_index + 1 });
                        this.freshCalendar(week_);
                      }}
                      style={{ color: '#ff6600', fontSize: 12, justifyContent: 'flex-start', padding: 0 }}
                    >
                      <IoIosArrowForward size={30} color="#ff6600" />
                    </Button>

                    <div style={{ columns: 7 }}>
                      {arrayWeekHeader &&
                        arrayWeekHeader.map((day, index) => {
                          const today = moment()
                            .startOf('day')
                            .format();
                          const date = moment(day.date)
                            .startOf('day')
                            .format();
                          return (
                            <div key={index}>
                              <div className={classes.header_day} style={{ color: date == today ? '#ff6600' : '#000' }}>
                                {moment(day.date).format('dddd')}
                              </div>
                              <Divider />
                              <div
                                className={classes.header_date}
                                style={{ color: date == today ? '#ff6600' : '#000' }}
                              >
                                {moment(day.date).format('DD MMM')}
                              </div>
                            </div>
                          );
                        })}
                    </div>

                    <div style={{ columns: 7 }}>
                      {arrayWeekCal &&
                        arrayWeekCal.map((slot, index) => {
                          return (
                            <Button
                              key={index}
                              classes={{
                                root: classes.time_slot,
                                disabled: classes.disabled,
                              }}
                              onClick={() => this.picSlot(slot.date)}
                              disabled={!slot.available}
                            >
                              <div>{slot.time}:00</div>
                            </Button>
                          );
                        })}
                    </div>

                    <Dialog
                      open={showModal}
                      onClose={() => this.setState({ showModal: !showModal })}
                      className="Modal"
                      maxWidth={'xl'}
                    >
                      <IconButton
                        aria-label="close"
                        style={{ width: 50, height: 50, position: 'absolute', right: 20 }}
                        onClick={() => this.setState({ showModal: false })}
                      >
                        <CloseIcon />
                      </IconButton>
                      <div
                        style={{
                          textAlign: 'left',
                          padding: '60px 30px',
                          fontSize: 16,
                          fontWeight: '600',
                          textTransform: 'capitalize',
                        }}
                      >
                        {this.state.selectedSlot != 'undefined' &&
                          moment(this.state.selectedSlot).format('dddd, Do MMMM')}
                      </div>
                      {arraySlot
                        .sort((a, b) => a.date.localeCompare(b.date))
                        .map((slot, index) => {
                          return (
                            <Link to={`/book/${slot.date}`} key={index} className={classes.time_slot}>
                              {moment(slot.date).format('HH:mm')}
                            </Link>
                          );
                        })}
                    </Dialog>
                  </div>
                }
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const CalendarWithData = withApollo(
  compose(
    graphql(QueryGetMember, {
      options: () => {
        const email = getUser();
        return {
          variables: { email: email },
          fetchPolicy: 'no-cache',
        };
      },
      props: ({ data: { getTeam: member, loading } }) => ({
        member,
        loading,
      }),
    }),
    graphql(MutationUpdateMemberStatus, {
      options: () => {
        const email = getUser();
        return {
          refetchQueries: [
            {
              query: QueryGetMember,
              variables: { email: email },
            },
          ],
        };
      },
      props: props => ({
        updateStatus: newstatus =>
          props.mutate({
            variables: newstatus,
          }),
      }),
    }),
    graphql(MutationCreateCalendar, {
      options: () => {
        const email = getUser();
        return {
          refetchQueries: [
            {
              query: QueryGetCalendar,
              variables: { email: email },
            },
          ],
        };
      },
      props: props => ({
        addCalendarItem: newSchedule =>
          props.mutate({
            variables: newSchedule,
          }),
      }),
    }),
    graphql(MutationUpdateCalendar, {
      options: () => {
        const email = getUser();
        return {
          refetchQueries: [
            {
              query: QueryGetCalendar,
              variables: { email: email },
            },
          ],
        };
      },
      props: props => ({
        updateCalendarItem: newSchedule =>
          props.mutate({
            variables: newSchedule,
          }),
      }),
    }),
    graphql(MutationDeleteCalendar, {
      options: () => {
        const email = getUser();
        return {
          refetchQueries: [
            {
              query: QueryGetCalendar,
              variables: { email: email },
            },
          ],
        };
      },
      props: props => ({
        deleteCalendarItem: deleteSchedule =>
          props.mutate({
            variables: deleteSchedule,
          }),
      }),
    })
  )(Calendar)
);

Calendar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CalendarWithData);
