import gql from 'graphql-tag';

export default gql(`
query ( $email:String! ) {
  listCalendars (filter:  {
     email: {eq: $email }
  }) {
    items {
      day
      email
      end
      id
      isOpen
      idDay
      start
      vendorID
    }
  }
}`);
