import gql from 'graphql-tag';

export default gql(`
mutation updadeTeam($email: ID! $status: Boolean, $enabled: Boolean, $location: String, $products: String, $profile: String) {
    updateTeam (
    input: {
        email: $email
        status: $status
        enabled: $enabled
        location: $location
        products: $products
        profile: $profile
      }
  ) {
    email
    name
    username
    vendorID
    profile
    location
    products
    status
    enabled
  }
}`);
