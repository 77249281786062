import gql from 'graphql-tag';

export default gql(`
query ListProducts($eventId:String) {
  listProducts (filter:  {
     eventId: {eq: $eventId }
  }) {
    items {
        name
        id
        createdAt
        url
        eventId
        image
    }
  }
}`);
