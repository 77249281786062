import gql from 'graphql-tag';

export default gql(`
mutation CreateTeam(
  $email: ID!
  $name: String!
  $username: String!
  $profile: String!
  $products: String!
  $createdAt: String!
  $status: Boolean!
  $enabled: Boolean!
  $vendorID: String!
  $location: String!

) {
  createTeam(
    input: {
    email: $email
    name: $name
    username: $username
    profile: $profile
    products: $products
    status: $status
    enabled: $enabled
    vendorID: $vendorID
    location: $location
    createdAt: $createdAt
    }
  ) {
    email
    name
    username
    profile
    products
    status
    enabled
    vendorID
    location
    createdAt
  }
}`);
