import gql from 'graphql-tag';

export default gql(`
mutation batchAddGuests($guests: [CreateGuestInput]) {
  batchAddGuests(guests : $guests)
{
    email
    name
    eventId
    onboard
    sponsor
  }
}`);
