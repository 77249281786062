import gql from 'graphql-tag';

export default gql(`
mutation UpdateCalendar($id:ID!, $email: String, $day: String, $end: String, $start: String, $isOpen: Boolean, $idDay: Int, $vendorID : String) {
    updateCalendar(input: {
      id : $id
      email : $email
      day : $day
      end : $end
      start : $start
      isOpen : $isOpen
      idDay : $idDay
      vendorID : $vendorID
    }
  ) {
      id
      email
      day
      end
      start
      isOpen
      idDay
      vendorID
    }
  }
`);
