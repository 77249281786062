import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { graphql, compose, withApollo } from 'react-apollo';

import QueryEventsToCome from '../graphql/QueryEventsToCome';
import MutationDeleteEvent from '../graphql/MutationDeleteEvent';
import MutationUpdateEventStatus from '../graphql/MutationUpdateEventStatus';

import { Auth } from 'aws-amplify';

import EventStatus from '../utils/EventStatus';

import { MdAddCircleOutline } from 'react-icons/md';
import { FcCalendar } from 'react-icons/fc';
import { BsPersonBoundingBox } from 'react-icons/bs';
import { RiTimerLine } from 'react-icons/ri';
import { GoSettings } from 'react-icons/go';
import { RiInformationLine, RiTimeLine } from 'react-icons/ri';
import { ImBubbles2 } from 'react-icons/im';
import { IoMdCloseCircle } from 'react-icons/io';
import { BiBarcode } from 'react-icons/bi';

import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/fr';
moment.locale('fr');

const timezone = moment.tz.guess();
const offSet = parseInt(moment.tz(timezone).format('Z'));

function getUser() {
  let user = Auth.user.attributes['email'];
  return user;
}

class AllEvents extends Component {
  state = {
    busy: false,
  };

  static defaultProps = {
    events: [],
    deleteEvent: () => null,
    cancelEvent: () => null,
  };

  async handleDeleteClick(event, e) {
    e.preventDefault();

    if (window.confirm(`Are you sure you want to delete event ${event.id}`)) {
      const { deleteEvent } = this.props;
      await deleteEvent(event);
      await this.handleSync();
    }
  }

  async handleCancelClick(event, e) {
    e.preventDefault();

    if (window.confirm(`Etes-vous sûre de vouloir annuler le rendez-vous`)) {
      const { cancelEvent } = this.props;
      await cancelEvent(event);
      await this.handleSync();
    }
  }

  handleSync = async () => {
    const { client } = this.props;
    const query = QueryEventsToCome;

    this.setState({ busy: true });
    const email = getUser();
    this.setState({ busy: true });
    await client.query({
      query,
      variables: { email: email },
      fetchPolicy: 'network-only',
    });

    this.setState({ busy: false });
  };

  renderProduct = product => {
    return (
      <div key={product.id}>
        <img src={product.image} width="100%" alt={product.name} border="0" style={{ display: 'block' }} />
        {/*<div className="eventDesc" style={{paddingTop:5,paddingBottom:5}} key={product.id}>{product.name}</div>*/}
      </div>
    );
  };

  renderEvent = event => {
    const result = EventStatus.find(data => data.value === event.status);
    const guests = event.guests.items;
    const sponsor =
      guests.filter(x => x.sponsor == true).length > 0
        ? guests.filter(x => x.sponsor == true)[0].name
        : event.guestName;
    return (
      <Link to={`/event/${event.id}`} className="card" key={event.id}>
        <div className="header" style={{ display: 'inline-block', padding: 20 }}>
          <div className="calendarIconXL">
            <FcCalendar size={50} />
          </div>
          <div className="calendarDate">
            <div className="calendarMoment">
              {moment(event.eventDate)
                .tz(timezone)
                .format('dddd, Do MMMM')}
            </div>
            <div className="calendarMoment">
              à{' '}
              {moment(event.eventDate)
                .tz(timezone)
                .format('HH:mm')}
            </div>
          </div>
        </div>
        {result && (
          <div
            className="eventStatus"
            style={{ borderRightWidth: 10, borderRightStyle: 'solid', borderRightColor: result['color'] }}
          >
            <div className="eventStatusLabel" style={{ color: result['color'] }}>
              {result['label']}
            </div>
          </div>
        )}
        <div className="LiveCard">
          <div style={{ height: 200, overflow: 'hidden', background: '#d2d2d2' }}>
            {event.products.items.length > 0 && (
              <img
                src={event.products.items[0].image}
                width="100%"
                alt={event.products.items[0].name}
                border="0"
                style={{
                  width: '100%',
                  height: 'auto',
                  maxHeight: 'none',
                  maxWidth: 'none',
                  display: 'block',
                  margin: 'auto',
                  position: 'relative',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%,-50%)',
                }}
              />
            )}
          </div>

          <div className="content" style={{ height: 160 }}>
            <div className="eventAttribute">
              <div className="eventIcon">
                <BsPersonBoundingBox size={20} />
              </div>
              <div className="eventDesc">Rendez-vous avec {sponsor}</div>
            </div>

            {event.maxduration && (
              <div className="eventAttribute">
                <div className="eventIcon">
                  <RiTimerLine size={20} />
                </div>
                <div className="eventDesc">{event.maxduration} minutes</div>
              </div>
            )}

            {event.description && (
              <div className="eventAttribute">
                <div className="eventIcon">
                  <RiInformationLine size={20} />
                </div>
                <div className="eventDesc">{event.description}</div>
              </div>
            )}

            <div className="eventAttribute">
              <div className="eventIcon">
                <ImBubbles2 size={20} />
              </div>
              <div className="eventDesc">{event.comments.items.length} Messages</div>
            </div>
          </div>
        </div>

        <button
          className="ui bottom attached button"
          style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
          onClick={this.handleCancelClick.bind(this, event)}
        >
          <IoMdCloseCircle />
          &nbsp;Annuler
        </button>
      </Link>
    );
  };

  render() {
    const { busy } = this.state;
    const { events } = this.props;
    return (
      <div className="ui container raised very padded segment">
        <h1 className="pageTitle" style={{ display: 'block', padding: '30px' }}>
          Prochains
          <br />
          rendez-vous
          <br />
          Live Shopping.
        </h1>
        <div className="addEvent" style={{ justifyContent: 'space-between', display: 'flex', padding: '0 25px' }}>
          <Link to="/calendar" className="addEventBtn">
            <MdAddCircleOutline size={50} color="#ff6600" style={{ paddignRight: 10 }} />
            Nouveau Live Shopping
          </Link>
          <button className="ui icon left basic button" onClick={this.handleSync} disabled={busy}>
            <i aria-hidden="true" className={`refresh icon ${busy && 'loading'}`}></i>
            Mettre à jour
          </button>
        </div>
        <div className="ui link cards" style={{ padding: 25 }}>
          {[]
            .concat(events)
            .sort((a, b) => a.eventDate.localeCompare(b.eventDate))
            .map(this.renderEvent)}

          <div
            style={{ alignItems: 'center', padding: '30px 0 20px 0', display: 'flex', color: '#666', width: '100%' }}
          >
            <RiTimeLine size={15} />
            <div style={{ paddingLeft: 7, fontSize: 13 }}>
              {timezone} {`(UTC${moment.tz(timezone).format('Z')})`}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withApollo(
  compose(
    graphql(QueryEventsToCome, {
      options: () => {
        const email = getUser();
        return {
          variables: { email: email },
          fetchPolicy: 'network-only',
        };
      },
      props: ({ data: { listEvents = { items: [] } } }) => ({
        events: listEvents.items,
      }),
    }),
    graphql(MutationDeleteEvent, {
      options: {
        update: (proxy, { data: { deleteEvent } }) => {
          const query = QueryEventsToCome;
          const data = proxy.readQuery({ query });

          data.listEvents.items = data.listEvents.items.filter(event => event.id !== deleteEvent.id);

          proxy.writeQuery({ query, data });
        },
      },
      props: props => ({
        deleteEvent: event => {
          return props.mutate({
            variables: { id: event.id },
            optimisticResponse: () => ({
              deleteEvent: {
                ...event,
                __typename: 'Event',
                comments: { __typename: 'CommentConnection', items: [] },
              },
            }),
          });
        },
      }),
    }),
    graphql(MutationUpdateEventStatus, {
      options: {
        update: (proxy, { data: { cancelEvent } }) => {
          const query = QueryEventsToCome;
          const data = proxy.readQuery({ query });

          data.listEvents.items = data.listEvents.items.filter(event => event.id !== cancelEvent.id);

          proxy.writeQuery({ query, data });
        },
      },
      props: props => ({
        cancelEvent: event => {
          return props.mutate({
            variables: { id: event.id, status: 'Z1' },
            optimisticResponse: () => ({
              cancelEvent: {
                ...event,
                __typename: 'Event',
                comments: { __typename: 'CommentConnection', items: [] },
              },
            }),
          });
        },
      }),
    })
  )(AllEvents)
);
