import gql from 'graphql-tag';

export default gql(`
mutation CreateEvent(
  $id: ID!
  $vendorID: String!
  $host: String!
  $location: String
  $createdAt: String
  $eventDate: String
  $status: String
  $description: String
  $maxduration: Int
  $maxguests: Int
) {
  createEvent(
    id:$id
    vendorID: $vendorID
    host: $host
    location: $location
    createdAt: $createdAt
    eventDate: $eventDate
    status: $status
    description: $description
    maxduration: $maxduration
    maxguests: $maxguests
  ) {
    id
    vendorID
    host
    location
    createdAt
    eventDate
    maxguests
    maxduration
    status
    roomID
    description
    comments {
      nextToken
    }
  }
}`);
