import gql from 'graphql-tag';

export default gql(`
query ($roomID:String) {
  listEvents (filter:  {
     roomID: {eq: $roomID }
  }) {
    items {
        id
        host
        guestName
        guestEmail
        location
        eventDate
        status
        description
        roomID
        maxduration
        maxguests
        vendorID
        ratings {
          items {
            rating
            createdAt
            user
          }
        }
        vendor {
          items {
            maxusers
            maxduration
            name
            website
          }
        }
        details {
          items {
            user
            start
            end
          }
        }
        products {
          items {
            url
            name
            eventId
            image
            id
            createdAt
          }
        }
      comments {
        items {
          commentId
          content
          createdAt
        }
      }
    }
  }
}`);
