import React, { useState } from 'react';
import ParticipantList from '../ParticipantList/ParticipantList';
import { styled } from '@material-ui/core/styles';
import MainParticipant from '../MainParticipant/MainParticipant';
import { makeStyles, Theme } from '@material-ui/core';

let vh = window.innerHeight * 0.01;

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '100%',
  display: 'grid',
  //gridTemplateColumns: 'calc(100% - 250px) 250px',
  //gridTemplateRows: '100%',
  [theme.breakpoints.down('sm')]: {
    //gridTemplateRows: `1fr 120px`,
    minWidth: '100vw',
    //gridTemplateColumns: '1fr 1fr 130px',
    minHeight: `calc( ${vh}px * 100 - 139px)`,
    alignIntems: 'center',
  },
}));

const ContainerMain = styled('div')(({ theme }) => ({
  display: 'block',
  minWidth: 'calc(100vw - 580px)',
  [theme.breakpoints.down('sm')]: {
    alignIntems: 'center',
    top: '50%',
    left: '50%',
    width: '100vw',
    transform: 'translate(-50%,-50%)',
    position: 'absolute',
  },
}));

const useStyles = makeStyles((theme: Theme) => ({
  brand: {
    position: 'absolute',
    color: 'rgb(255,255,255,0.8)',
    bottom: 30,
    right: 30,
    zIndex: 99999,
    textTransform: 'uppercase',
    fontSize: 11,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      bottom: 10,
      right: 15,
    },
  },
}));

export default function Room(props) {
  const [vendor] = useState<string>(props.vendor);
  const classes = useStyles();

  return (
    <Container>
      <div className={classes.brand}>{vendor}</div>
      <ContainerMain>
        <MainParticipant />
      </ContainerMain>
      <ParticipantList />
    </Container>
  );
}
