import React, { useCallback, useRef, useContext, useState, ChangeEvent, FormEvent } from 'react';
import { Link } from 'react-router-dom';

import { Mutation, graphql } from 'react-apollo';

import Amplify, { API, Auth, Storage, Analytics, graphqlOperation } from 'aws-amplify';

import clsx from 'clsx';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import useVideoDetails from '../../../hooks/useVideoDetails';

import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import { useHasVideoInputDevices } from '../../../hooks/deviceHooks/deviceHooks';

import MutationCreateVideoConnection from '../../../graphql/MutationCreateVideoConnection';
import MutationCreateRating from '../../../graphql/MutationCreateRating';

import { Divider, DialogContent, Dialog, Button, DialogTitle, DialogActions } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import moment from 'moment';

import { MdCallEnd } from 'react-icons/md';
import { ImStarEmpty } from 'react-icons/im';
import { FaRegClosedCaptioning } from 'react-icons/fa';
import { GrNewWindow } from 'react-icons/gr';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      '&:hover': {
        background: '#600101',
      },
    },
  })
);

interface EventInter {
  id: String;
  status: string;
}

export default function EndCallButton(props: { className?: string }) {
  //const classes = useStyles();
  const { room } = useVideoContext();

  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const hasVideoDevices = useHasVideoInputDevices();

  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.some(track => track.kind === 'audio');

  const [stars, setStars] = useState(5);
  const [modalrating, setModalrating] = useState<boolean>(false);

  const details = useVideoDetails();
  const start = moment.utc().format();

  const contextdtls = {
    start: start,
    user: details.user,
    roomID: details.roomID,
    vendorID: details.vendorID,
    eventId: details.eventId,
  };

  const varRating = {
    eventId: details.eventId,
    vendorID: details.vendorID,
    user: details.user,
  };

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 100) {
      lastClickTimeRef.current = Date.now();
      if (isVideoEnabled) {
        toggleVideoEnabled();
      }
      if (isAudioEnabled) {
        toggleAudioEnabled();
      }
    }
  }, [toggleVideoEnabled]);

  const handleChangeRating = async (event: any) => {
    var newvalue = event.target.value;
    await setStars(parseInt(newvalue));
  };

  const EndEvent = async () => {
    const data = {
      ...varRating,
      rating: stars,
      createdAt: moment.utc().format(),
    };
    //const apiData = await API.graphql(graphqlOperation(MutationUpdateEventStatus,varEvent))
    const apiData = await API.graphql(graphqlOperation(MutationCreateRating, data));
    room.disconnect();
  };

  return (
    <Mutation mutation={MutationCreateVideoConnection} variables={contextdtls}>
      {createVideo => (
        <div>
          <Button
            onClick={() => {
              toggleVideo();
              setModalrating(true);
            }}
            className="videoBtn"
            data-cy-disconnect
          >
            <MdCallEnd size={25} color={'#d93025'} />
          </Button>

          <Dialog
            open={modalrating}
            onClose={event => {
              setModalrating(false);
            }}
            className="HistoryModal"
            disableBackdropClick
          >
            <DialogTitle>C'est déjà la fin de votre Live Shopping</DialogTitle>
            <Divider />
            <DialogContent>
              <div style={{ padding: 20, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                Merci de nous faire part de votre avis sur votre expérience Live Shopping
              </div>
              <div style={{ paddingBottom: 20, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                <Rating name="simple-controlled" value={stars} onChange={handleChangeRating} size="large" />
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={event => {
                  setModalrating(false);
                }}
                variant="outlined"
                color="primary"
              >
                Annuler
              </Button>
              <Link
                to={{ pathname: `/event/${contextdtls.eventId}`, state: 'flushDeal' }}
                onClick={() => {
                  const end = moment.utc().format();
                  const videodtls = { ...contextdtls, end: end };
                  createVideo({ variables: videodtls });
                  EndEvent();
                }}
                color="primary"
                className="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary"
              >
                Valider
              </Link>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </Mutation>
  );
}
