import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { graphql, compose, withApollo } from 'react-apollo';

import { Calendar, momentLocalizer } from 'react-big-calendar';

import { Link } from 'react-router-dom';

import QueryGetCalendar from '../graphql/QueryGetCalendar';
import QueryAllCalendars from '../graphql/QueryAllCalendars';
import QueryActiveVendorEvents from '../graphql/QueryActiveVendorEvents';
import QueryAllActiveEvents from '../graphql/QueryAllActiveEvents';

import QueryGetMember from '../graphql/QueryGetMember';
import MutationUpdateMemberStatus from '../graphql/MutationUpdateMemberStatus';
import QueryGetVendor from '../graphql/QueryGetVendor';

import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { RiTimeLine } from 'react-icons/ri';

import { withStyles } from '@material-ui/core/styles';

import 'react-big-calendar/lib/css/react-big-calendar.css';

import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/fr';
moment.locale('fr');

const localizer = momentLocalizer(moment);

const stc = require('string-to-color');

function getUser() {
  let user = Auth.user.attributes['email'];
  return user;
}

const timezone = moment.tz.guess();
const offSet = parseInt(moment.tz(timezone).format('Z'));

const styles = theme => ({
  time_slot: {
    minHeight: 60,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    height: '100%',
    position: 'relative',
    borderRadius: 3,
    padding: '10px 3px',
    background: '#d6ebff',
    margin: '0px 5px 7px 0px',
    fontWeight: '600',
    color: '#152638',
    fontFamily: 'ProximaNova,sans-serif',
    '&:hover': {
      background: '#054875',
      color: '#fff',
    },
  },
  disabled: {
    background: '#e2e2e2',
  },
  header_day: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    position: 'relative',
    padding: '10px 3px',
    margin: '0 5px 0 0',
    fontWeight: '600',
    fontSize: 20,
    textTransform: 'capitalize',
    color: '#152638',
    fontFamily: 'ProximaNova,sans-serif',
  },
  header_date: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    position: 'relative',
    padding: '10px 3px',
    margin: '0 5px 15px 0',
    fontSize: 18,
    textTransform: 'capitalize',
    color: '#152638',
    fontFamily: 'ProximaNova,sans-serif',
  },
});

class FullCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      week_index: 0,
      allTeam: true,
      isEvents: true,
      events: [],
      member: {},
      calendars: [],
      arraySlot: [],
      ArrayEvents: [],
      event: {
        title: '',
        date: '',
        id: '',
      },
      selectedSlot: 'undefined',
      needUpdate: false,
      showModal: false,
    };
    this.initialState = { ...this.state };
  }

  capitalize = text => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  componentDidMount = async () => {
    const { client } = this.props;
    const email = getUser();
    const member = await client.query({
      query: QueryGetMember,
      variables: { email: email },
      fetchPolicy: 'network-only',
    });
    this.setState({ member: member.data.getTeam });
    console.log(this.state.member);
    await this.getEvents(this.state.allTeam);
    await this.getCalendars(this.state.allTeam);
    this.freshCalendar();
  };

  setTeam = async allTeam => {
    await this.getEvents(allTeam);
    await this.getCalendars(allTeam);
    this.freshCalendar(this.state.week_index);
    this.setState({ allTeam: allTeam });
  };

  getEvents = async allTeam => {
    const { client } = this.props;
    const { member } = this.state;

    const TeamVar = { vendorID: member.vendorID };
    const MemberVar = { email: member.email };

    const result = await client.query({
      query: allTeam ? QueryActiveVendorEvents : QueryAllActiveEvents,
      variables: allTeam ? TeamVar : MemberVar,
      fetchPolicy: 'network-only',
    });
    this.setState({
      events: result.data.listEvents.items,
    });
  };

  getCalendars = async allTeam => {
    const { client } = this.props;
    const { member } = this.state;

    const TeamVar = { vendorID: member.vendorID };
    const MemberVar = { email: member.email };

    const result = await client.query({
      query: allTeam ? QueryAllCalendars : QueryGetCalendar,
      variables: allTeam ? TeamVar : MemberVar,
      fetchPolicy: 'network-only',
    });
    this.setState({
      calendars: result.data.listCalendars.items,
    });
  };

  freshCalendar() {
    const { events, calendars } = this.state;

    /* Events  */
    let ArrayEvents = [];
    {
      events &&
        events.forEach((row, index) => {
          var name = row.host.split('@');
          ArrayEvents.push({
            id: row.id,
            title: this.capitalize(name[0]),
            allDay: false,
            start: moment(row.eventDate)
              .tz(timezone)
              .toDate(),
            end: moment(row.eventDate)
              .add(row.maxduration, 'm')
              .tz(timezone)
              .toDate(),
            desc: `Live Shopping avec ${row.guestName} (${row.roomID})`,
            color: stc(name[0]),
          });
        });
    }
    this.setState({ ArrayEvents });

    let ArrayAvailable = [];
    var MONTH_STARTS = moment()
      .startOf('month')
      .format();

    {
      calendars &&
        calendars.forEach((row, index) => {
          var DAY = row.idDay;
          var name = row.email.split('@');

          for (let i = 0; i < 8; ++i) {
            var row_date = moment(MONTH_STARTS)
              .add(DAY - 1 + 7 * i, 'd')
              .format();
            var row_date_start = moment(row_date)
              .startOf('day')
              .format();

            var slot_start = 60 * (parseInt(row.start.slice(0, 2)) + offSet) + parseInt(row.start.slice(3, 5)); //converti en minutes le début de la plage
            var slot_end = 60 * (parseInt(row.end.slice(0, 2)) + offSet) + parseInt(row.end.slice(3, 5)); //converti en minutes le fin de la plage
            let start_hour = moment(row_date_start)
              .add(slot_start, 'm')
              .format();
            let end_hour = moment(row_date_start)
              .add(slot_end, 'm')
              .format();

            ArrayAvailable.push({
              id: row.id,
              title: this.capitalize(name[0]),
              allDay: false,
              start: moment(start_hour).toDate(),
              end: moment(end_hour).toDate(),
              color: stc(name[0]),
            });
          }
        });
    }
    this.setState({ ArrayAvailable });
  }

  render() {
    const { showModal, allTeam, ArrayAvailable, ArrayEvents } = this.state;
    return (
      <div className="ui container raised very padded segment">
        <h1 className="pageTitle" style={{ display: 'block', padding: '30px' }}>
          Calendrier
          <br />
          disponibilités
          <br />
          {'&'} rendez-vous.
        </h1>
        <div className="content">
          <FormControlLabel
            control={
              <Switch
                checked={this.state.isEvents}
                onChange={() => {
                  this.setState({ isEvents: !this.state.isEvents });
                }}
                name="events"
                //color="red"
              />
            }
            label={this.state.isEvents ? 'Rendez-Vous Live Shopping' : 'Plages disponibles'}
          />

          <FormControlLabel
            control={
              <Switch
                checked={allTeam}
                onChange={() => {
                  this.setTeam(!allTeam);
                }}
                name="team"
                color="primary"
              />
            }
            label={allTeam ? "Toute l'équipe" : 'Moi uniquement'}
          />

          <div style={{ alignItems: 'center', padding: '7px 0 20px 0', display: 'flex', color: '#666' }}>
            <RiTimeLine size={15} />
            <div style={{ paddingLeft: 7, fontSize: 13 }}>
              {timezone} {`(UTC${moment.tz(timezone).format('Z')})`}
            </div>
          </div>

          <div style={{ padding: '30px 0' }}>
            {ArrayEvents && ArrayAvailable && (
              <Calendar
                localizer={localizer}
                events={this.state.isEvents ? ArrayEvents : ArrayAvailable}
                startAccessor="start"
                endAccessor="end"
                defaultView="week"
                min={new Date(2020, 1, 0, 8, 0, 0)}
                max={new Date(2021, 12, 0, 21, 0, 0)}
                popup={false}
                style={{ height: 500 }}
                eventPropGetter={event => ({
                  style: {
                    backgroundColor: event.color,
                    border: 0,
                  },
                })}
                onSelectEvent={event => {
                  this.state.isEvents ? this.setState({ showModal: true, event }) : this.setState({ event });
                }}
              />
            )}
          </div>

          <Dialog open={showModal} onClose={() => this.setState({ showModal: !showModal })} className="Modal">
            <IconButton
              aria-label="close"
              style={{ width: 50, height: 50, position: 'absolute', right: 20 }}
              onClick={() => this.setState({ showModal: false })}
            >
              <CloseIcon />
            </IconButton>
            <div style={{ padding: '60px 30px', fontSize: 13, fontWeight: 'bold' }}>
              <div>{moment(this.state.event.start).format('dddd, Do MMMM à HH:mm')}</div>
              <div>{this.state.event.title}</div>
              <div>{this.state.event.desc}</div>
            </div>
            <div className="ui buttons">
              <Link to={`/event/${this.state.event.id}`} className="ui button" color="primary" variant="outlined">
                Voir le détail
              </Link>
            </div>
          </Dialog>
        </div>
      </div>
    );
  }
}

const CalendarWithData = withApollo(
  compose(
    graphql(QueryGetMember, {
      options: () => {
        const email = getUser();
        return {
          variables: { email: email },
          fetchPolicy: 'no-cache',
        };
      },
      props: ({ data: { getTeam: member, loading } }) => ({
        member,
        loading,
      }),
    })
  )(FullCalendar)
);

export default CalendarWithData;
