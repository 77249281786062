import gql from 'graphql-tag';

export default gql(`
mutation updateEvent($id: ID! $status: String) {
    updateEvent (
    input: {
        id: $id
        status: $status
      }
  ) {
    id
      host
      guestName
      guestEmail
      location
      eventDate
      status
      description
      roomID
      products {
        items {
          url
          createdAt
           image
          name
          id
        }
      }
      ratings {
        items {
          rating
          createdAt
          user
        }
      }
      details {
        items {
          user
          start
          end
        }
      }
    comments {
      __typename
      items {
        commentId
        content
        createdAt
      }
    }
  }
}`);
