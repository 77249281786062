import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { v4 as uuid } from 'uuid';

import MutationCommentOnEvent from '../graphql/MutationCommentOnEvent';
import QueryGetEvent from '../graphql/QueryGetEvent';
import moment from 'moment';
import { RiChatCheckFill } from 'react-icons/ri';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
});

class NewComment extends Component {
  constructor(props) {
    super(props);
  }
  static defaultProps = {
    createComment: () => null,
  };

  static defaultState = {
    comment: {
      content: '',
    },
    loading: false,
  };

  state = NewComment.defaultState;

  handleSubmit = async e => {
    e.stopPropagation();
    e.preventDefault();
    const { comment } = this.state;
    const { eventId, createComment, user, name } = this.props;

    this.setState({ loading: true });

    const newcomment = this.state.comment.content.replace(/[\r\n\t]/g, ' ');

    await createComment({
      content: newcomment,
      eventId,
      user: user,
      name: name,
      createdAt: moment.utc().format(),
    });

    this.setState(NewComment.defaultState);
  };

  handleChange = ({ target: { value: content } }) => {
    this.setState({ comment: { content } });
  };

  handleKeyDown = evt => {
    if (['Enter', 'Tab'].includes(evt.key)) {
      this.handleSubmit.bind(this);
    }
  };

  render() {
    const { comment, loading } = this.state;
    const { classes } = this.props;
    return (
      <div className="field">
        <Paper component="form" className={classes.root}>
          <InputBase
            className={classes.input}
            placeholder="votre message..."
            //inputProps={{ 'aria-label': 'votre message...' }}
            value={comment.content}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
            disabled={loading}
          />
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton type="submit" className={classes.iconButton} aria-label="search" onClick={this.handleSubmit}>
            <RiChatCheckFill color={'#ff6600'} />
          </IconButton>
        </Paper>
      </div>
    );
  }
}

const NewCommentWithData = graphql(MutationCommentOnEvent, {
  options: props => ({
    update: (proxy, { data: { commentOnEvent } }) => {
      const query = QueryGetEvent;
      const variables = { id: commentOnEvent.eventId };
      const data = proxy.readQuery({ query, variables });

      data.getEvent = {
        ...data.getEvent,
        comments: {
          ...data.getEvent.comments,
          items: [
            ...data.getEvent.comments.items.filter(c => c.commentId !== commentOnEvent.commentId),
            commentOnEvent,
          ],
        },
      };

      proxy.writeQuery({ query, data });
    },
  }),
  props: props => ({
    createComment: comment => {
      return props.mutate({
        variables: { ...comment },
        optimisticResponse: { commentOnEvent: { ...comment, __typename: 'Comment', commentId: uuid() } },
      });
    },
  }),
})(NewComment);

export default withStyles(styles)(NewCommentWithData);
