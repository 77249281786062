import React, { Component, useEffect, createContext } from 'react';
import { graphql, compose, withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';

import useVideoContext from '../hooks/useVideoContext/useVideoContext';

import { withStyles } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';

import DeviceSelectionScreen from './AutoJoinScreens/DeviceSelectionScreen/DeviceSelectionScreen';
import ReconnectingNotification from './ReconnectingNotification/ReconnectingNotification';
import Room from './Room/Room';
import MenuBar from './MenuBar/MenuBar';
import MobileTopMenuBar from './MobileTopMenuBar/MobileTopMenuBar';

import EventProducts from './EventProducts';

import useRoomState from '../hooks/useRoomState/useRoomState';

import QueryEventByRoom from '../graphql/QueryEventByRoom';

let vh = window.innerHeight * 0.01;

function RoomState(Component) {
  return function WrappedComponent(props) {
    const roomState = useRoomState();
    return <Component {...props} roomState={roomState} />;
  };
}

const init = {
  user: 'no() set',
  vendorID: 'not set',
  roomID: 'not set',
  eventId: 'not set',
};

const styles = theme => ({
  drawerPaper: {
    top: 0,
    width: 330,
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '15px 15px 0 0',
      top: 'calc(100vh - 370px)',
    },
  },
  EventVideo: {
    width: 'calc(100vw - 580px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
  },
  videoProducts: {
    width: '100%',
    padding: 20,
    overflow: 'unset',
    background: '#fff !important',
    zIndex: 9,
  },
  videoContainer: {
    backgroundColor: '#000',
    [theme.breakpoints.down('sm')]: {
      minHeight: `calc( ${vh}px * 100 - 140px)`,
    },
  },
  videoLayout: {
    width: 'calc(100vw - 580px)',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      height: `calc( ${vh}px * 100 - 139px)`,
      width: '100vw',
      background: '#000',
    },
  },
});

export const VideoDetails = createContext(init);

class Join extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProducts: false,
      width: 0,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handler = this.handler.bind(this);
  }

  handler() {
    this.setState({
      showProducts: !this.state.showProducts,
    });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
    if (window.innerWidth < 850) {
      this.setState({ showProducts: false });
    } else {
      this.setState({ showProducts: true });
    }
  }

  render() {
    const roomState = this.props.roomState;
    const roomName = this.props.match.params.roomID;
    const name = this.props.match.params.name;
    const { event, classes } = this.props;

    return (
      <div>
        {event && (
          <div className={classes.EventVideo}>
            {roomState === 'disconnected' ? (
              <VideoDetails.Provider
                value={{ roomID: event.roomID, user: event.guestEmail, vendorID: event.vendorID, eventId: event.id }}
              >
                <DeviceSelectionScreen name={name} roomName={roomName} setStep={'Steps.roomNameStep'} />
              </VideoDetails.Provider>
            ) : (
              <VideoDetails.Provider
                value={{ roomID: event.roomID, user: event.guestEmail, vendorID: event.vendorID, eventId: event.id }}
              >
                <ReconnectingNotification />
                <div className={classes.videoLayout}>
                  <Room vendor={event.vendor.items[0].name} />
                </div>
                <MenuBar action={this.handler} />
              </VideoDetails.Provider>
            )}

            <Drawer
              variant={this.state.width > 800 ? 'permanent' : 'temporary'}
              anchor={this.state.width > 800 ? 'right' : 'bottom'}
              open={this.state.showProducts}
              onClose={() => this.setState({ showProducts: false })}
              className={classes.drawer}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <div className="videoProducts">
                <EventProducts
                  vendorID={event.vendorID}
                  eventId={event.id}
                  website={event.vendor.items[0].website}
                  action={this.handler}
                />
              </div>
            </Drawer>
          </div>
        )}
      </div>
    );
  }
}

const joinRoom = withApollo(
  compose(
    graphql(QueryEventByRoom, {
      options: ({
        match: {
          params: { roomID },
        },
      }) => ({
        variables: { roomID },
        fetchPolicy: 'network-only',
      }),
      props: ({ data: { listEvents = { items: [] } } }) => ({
        event: listEvents.items[0],
      }),
    })
  )(Join)
);

export default withRouter(RoomState(withStyles(styles)(joinRoom)));
