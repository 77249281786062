import React, { Component } from 'react';
import { graphql, compose, withApollo } from 'react-apollo';

import moment from 'moment';

import { v4 as uuid } from 'uuid';

import { Divider, Dialog, DialogContent, Button, DialogTitle, DialogActions, TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { MdAddCircleOutline } from 'react-icons/md';
import { IoMdClose, IoIosArrowForward } from 'react-icons/io';

import CircularProgress from '@material-ui/core/CircularProgress';

import MutationProductOnEvent from '../graphql/MutationProductOnEvent';
import QueryProducts from '../graphql/QueryProducts';

import SubsriptionProductsUpdate from '../graphql/SubsriptionProductsUpdate';
import MutationDeleteProduct from '../graphql/MutationDeleteProduct';

class EventProducts extends Component {
  static defaultProps = {
    products: [],
    handleRemove: () => null,
    handleSubmit: () => null,
    createProduct: () => null,
  };

  static defaultState = {
    product: {
      url: '',
    },
    loading: false,
    showProductModal: false,
    error: null,
  };

  state = EventProducts.defaultState;

  /*subscription;

    componentDidMount() {
        this.subscription = this.props.subscribeToProducts();
    }

    componentWillUnmount() {
        this.subscription();
    }
    */

  handleRemove = async (product, e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ loading: true });

    const { deleteProduct } = this.props;
    await deleteProduct(product);
    this.setState(EventProducts.defaultState);
  };

  handleSubmit = async e => {
    e.stopPropagation();
    e.preventDefault();
    const { product } = this.state;
    const { eventId, website, createProduct } = this.props;
    const { client } = this.props;

    this.setState({ loading: true });

    if (product.url.includes(website)) {
      await createProduct({
        ...product,
        eventId,
        createdAt: moment.utc().format(),
      });

      setTimeout(async () => {
        await client.query({
          query: QueryProducts,
          variables: { eventId: eventId },
          fetchPolicy: 'network-only',
        });
      }, 2500);
    } else {
      this.setState({ error: `Merci de saisir une page du site ${website}` });
      this.setState({ loading: false });
    }
    this.setState(EventProducts.defaultState);
  };

  handleChange = ({ target: { value: url } }) => {
    this.setState({ product: { url } });
    this.setState({ error: null });
  };

  renderProduct = product => {
    return (
      <div
        className="product"
        key={product.id}
        style={{ marginBottom: 30, paddingBottom: 30, borderBottom: '1px solid #d2d2d2' }}
      >
        <div>
          {this.state.loading && (
            <CircularProgress
              style={{
                padding: 50,
                color: '#ff6600',
                alignContent: 'center',
                margin: '0 auto',
                textAlign: 'center',
                flex: 1,
                display: 'block',
              }}
            />
          )}
          <div>
            <img src={product.image} width="100%" />
          </div>
          <div className="text" style={{ paddingTop: 7, paddingBottom: 7 }}>
            {product.name}
          </div>
          <div style={{ justifyContent: 'space-between', display: 'flex', paddingTop: 15, paddingBottom: 15 }}>
            <Button
              onClick={e => {
                e.preventDefault();
                window.open(product.url);
              }}
              style={{ border: '1px solid #ff6600', color: '#ff6600' }}
            >
              <IoIosArrowForward size={15} />
              &nbsp;En savoir plus&nbsp;
            </Button>
            <Button onClick={this.handleRemove.bind(this, product)} style={{ color: '#777' }}>
              Retirer de ma liste&nbsp;
              <IoMdClose size={15} />
            </Button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { products, eventId } = this.props;
    const { product, loading, showProductModal, error } = this.state;

    return (
      <div className="eventCard" style={{ flex: 1, clear: 'none', padding: '3px 7px', width: '100%', marginTop: 7 }}>
        {products.length < 3 ? (
          <Button
            onClick={() => this.setState({ showProductModal: true })}
            style={{ color: '#ff6600', fontSize: 12, justifyContent: 'flex-start', padding: 0 }}
          >
            <MdAddCircleOutline size={30} color="#ff6600" />
            &nbsp;Ajouter un produit
          </Button>
        ) : (
          <div style={{ padding: 15, fontSize: 12 }}>
            Vous avez atteint le nombre maximum de produits pour un Live Shopping. Vous pouvez changer votre sélection
            retirant des produits puis en ajouter de nouveaux.
          </div>
        )}
        {products.length > 0 && (
          <div style={{ padding: 15, alignItems: 'center', justifyContent: 'center' }}>
            {products.sort((a, b) => b.createdAt.localeCompare(a.createdAt)).map(this.renderProduct)}
          </div>
        )}
        <Dialog
          open={showProductModal}
          onClose={() => this.setState({ showProductModal: !showProductModal })}
          className="HistoryModal"
        >
          <IconButton
            aria-label="close"
            style={{ width: 50, height: 50, position: 'absolute', right: 20 }}
            onClick={() => this.setState({ showProductModal: false })}
          >
            <CloseIcon />
          </IconButton>
          <div style={{ textAlign: 'left', paddingTop: 15, paddingBottom: 15, fontSize: 16 }}>Ajouter un produit</div>
          <Divider />
          <TextField
            autoFocus
            margin="dense"
            id="URL"
            label="Copier/coller l'URL du produit"
            type="text"
            value={product.url}
            onChange={this.handleChange}
            fullWidth
            style={{ minWidth: 350, marginTop: 30, fontSize: 13, paddingBottom: 15 }}
          />
          <div style={{ color: 'red', paddingTop: 10, paddingBottom: 20 }}>{error}</div>
          <DialogActions>
            <Button
              onClick={() => this.setState({ showProductModal: !showProductModal })}
              color="primary"
              variant="outlined"
            >
              Annuler
            </Button>
            <Button
              onClick={this.handleSubmit.bind(this)}
              color="primary"
              className={`${loading ? 'loading' : ''}`}
              variant="outlined"
              disabled={loading}
            >
              Valider
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const EventProductsWithData = withApollo(
  compose(
    graphql(QueryProducts, {
      options: props => {
        return {
          variables: { eventId: props.eventId },
          fetchPolicy: 'cache-and-network',
        };
      },
      props: ({ data: { listProducts = { items: [] } } }) => ({
        products: listProducts.items,
      }),
    }),
    graphql(MutationDeleteProduct, {
      options: props => {
        return {
          refetchQueries: [
            {
              query: QueryProducts,
              variables: { eventId: props.eventId },
            },
          ],
        };
      },
      props: props => ({
        deleteProduct: product => {
          return props.mutate({
            variables: { id: product.id },
            optimisticResponse: () => ({
              deleteProduct: {
                ...product,
                __typename: 'Product',
              },
            }),
          });
        },
      }),
    }),
    graphql(MutationProductOnEvent, {
      options: props => {
        return {
          refetchQueries: [
            {
              query: QueryProducts,
              variables: { eventId: props.eventId },
            },
          ],
        };
      },
      props: props => ({
        createProduct: product => {
          return props.mutate({
            variables: { ...product },
            optimisticResponse: { productOnEvent: { ...product, __typename: 'Product' } },
          });
        },
      }),
    })
  )(EventProducts)
);

export default EventProductsWithData;
