import gql from 'graphql-tag';

export default gql(`
query ($email:String!) {
  listEvents (filter: {
    and: {
     host: {eq: $email },
     or: {
     status: {contains: "A"},
     status: {contains: "C"}
     }
   }
   }) {
    items {
      id
      host
      guestName
      guestEmail
      location
      eventDate
      status
      description
      maxduration
      maxguests
      roomID
      guests(limit: 1000) {
        items {
          name
          email
          sponsor
          onboard
        }
      }
      vendor {
        items {
          maxusers
          maxduration
          name
          website
        }
      }
      products {
        items {
          url
          name
          eventId
          image
          id
          createdAt
        }
      }
      comments {
        items {
          commentId
        }
      }
    }
  }
}`);
