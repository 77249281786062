import gql from 'graphql-tag';

export default gql(`
query ($vendorID:String!) {
  listTeams (filter:  {
     vendorID: {eq: $vendorID }
  }) {
    items {
      username
      email
      name
      profile
      enabled
      status
      location
      rating
      vendor {
        items {
          enabled
          customerdemos
          id
          maxduration
          maxusers
          name
          website
          style
        }
      }
    }
  }
}`);
