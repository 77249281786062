import gql from 'graphql-tag';

export default gql(`
mutation($id: ID!) {
  deleteProduct(input:{
      id: $id
  }) {
    id
    url
    name
    eventId
  }
}`);
