import gql from 'graphql-tag';

export default gql(`
mutation CreateRating(
    $user: String
	$eventId: String
	$createdAt: String
	$rating: Float
    $vendorID: String!    
) {
  createRating(
    input: {
    user: $user
    eventId: $eventId
    rating: $rating
    vendorID: $vendorID
    createdAt: $createdAt
    }
  ) {
    id
    user
    eventId
    rating
    vendorID
    createdAt
  }
}`);
