import React, { Component, useState } from 'react';

import { Link } from 'react-router-dom';
import { Router, browserHistory } from 'react-router';

import { graphql, compose, withApollo } from 'react-apollo';

import QueryGetMember from '../graphql/QueryGetMember';
import MutationUpdateTeamMember from '../graphql/MutationUpdateTeamMember';

import { MdClose } from 'react-icons/md';

class ViewMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      member: props.member,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.member !== state.member) {
      return { member: props.member };
    }
    return null;
  }

  handleChange(field, { target: { value } }) {
    const { member } = this.state;
    member[field] = value;
    this.setState({ member });
  }

  handleSave = async e => {
    e.preventDefault();
    const { updateMember } = this.props;
    const { member } = this.state;
    const memberVar = {
      email: member.email,
      status: member.status,
      enabled: member.enabled,
      location: member.location,
      products: member.products,
      profile: member.profile,
    };
    await updateMember(memberVar);
    window.confirm(`Les informations ont été mises à jour`);
    browserHistory.push('/profile');
  };

  render() {
    const { member } = this.state;
    console.log(member);

    return (
      <div>
        {member && (
          <div className="ui container raised very padded segment">
            <div style={{ position: 'absolute', top: 50, right: 50 }}>
              <Link to="/profile">
                <MdClose color={'#777'} size={40} />
              </Link>
            </div>
            <h1 className="ui header">{member.name}</h1>
            <div className="ui form">
              <div className="field eight wide">
                <label htmlFor="name">Username</label>
                <input
                  style={{ backgroundColor: '#f2f2f2' }}
                  type="text"
                  id="username"
                  readOnly={true}
                  value={member.username}
                />
              </div>
              <div className="field eight wide">
                <label htmlFor="name">Email</label>
                <input
                  type="text"
                  style={{ backgroundColor: '#f2f2f2' }}
                  readOnly={true}
                  id="email"
                  value={member.email}
                />
              </div>
              <div className="field eight wide">
                <label htmlFor="profil">Profil</label>
                <div className="formfield-select--container">
                  <select defaultValue={member.profile} onChange={this.handleChange.bind(this, 'profile')}>
                    <option value="Administrateur">Admin</option>
                    <option value="Vendeur">Vendeur</option>
                    <option value="Client">Client</option>
                  </select>
                </div>
              </div>
              <div className="field eight wide">
                <label htmlFor="where">Magasin</label>
                <input
                  type="text"
                  id="location"
                  value={member.location}
                  onChange={this.handleChange.bind(this, 'location')}
                />
              </div>
              <div className="field eight wide">
                <label htmlFor="product">Produits</label>
                <input
                  type="text"
                  id="products"
                  value={member.products}
                  onChange={this.handleChange.bind(this, 'products')}
                />
              </div>
              <div className="field eight wide">
                <label htmlFor="where">Disponibilité</label>
                <div className="formfield-select--container">
                  <select defaultValue={member.status} onChange={this.handleChange.bind(this, 'status')}>
                    <option value={true}>Disponible</option>
                    <option value={false}>Indisponible</option>
                  </select>
                </div>
              </div>
              <div className="field eight wide">
                <label htmlFor="where">Compte utisateur</label>
                <div className="formfield-select--container">
                  <select defaultValue={member.enabled} onChange={this.handleChange.bind(this, 'enabled')}>
                    <option value={true}>Compte actif</option>
                    <option value={false}>Compte inactif</option>
                  </select>
                </div>
              </div>
              <div className="ui buttons">
                <Link to="/profile" className="ui button">
                  Annuler
                </Link>
                <div className="or"></div>
                <button className="ui positive button" onClick={this.handleSave}>
                  Valider
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const ViewMemberWithData = withApollo(
  compose(
    graphql(QueryGetMember, {
      options: ({
        match: {
          params: { email },
        },
      }) => ({
        variables: { email },
        fetchPolicy: 'network-only',
      }),
      props: ({ data: { getTeam: member, loading } }) => ({
        member,
        loading,
      }),
    }),
    graphql(MutationUpdateTeamMember, {
      options: ({
        match: {
          params: { email },
        },
      }) => {
        return {
          refetchQueries: [
            {
              query: QueryGetMember,
              variables: { email },
            },
          ],
        };
      },
      props: props => ({
        updateMember: memberVar =>
          props.mutate({
            variables: memberVar,
          }),
      }),
    })
  )(ViewMember)
);

export default ViewMemberWithData;
