import gql from 'graphql-tag';

export default gql(`
mutation MutationCommentOnEvent($eventId: ID! $content: String! $createdAt: String! $user:String $name:String) {
  commentOnEvent(
    eventId: $eventId
    content: $content
    createdAt: $createdAt
    user: $user
    name: $name

  ) {
    eventId
    commentId
    content
    createdAt
    user
    name
  }
}`);
