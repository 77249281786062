import gql from 'graphql-tag';

export default gql(`
query ( $vendorID:String ) {
  listCalendars (filter:  {
    and:{
     vendorID: {eq: $vendorID },
    isOpen: { eq: true }
    }
  }) {
    items {
      day
      email
      end
      id
      isOpen
      idDay
      start
      vendorID
    }
  }
}`);
