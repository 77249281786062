import React, { useState, useEffect, useCallback, useRef } from 'react';

import { Link } from 'react-router-dom';

import { makeStyles, Grid, Button, Theme, Hidden } from '@material-ui/core';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import { LocalVideoTrack, LocalAudioTrack } from 'twilio-video';
import SettingsMenu from './SettingsMenu/SettingsMenu';
import { Steps } from '../AutoJoinScreens';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import PreflightTest from '../PreflightTest/PreflightTest';
import { useAppState } from '../../../state';
import MediaErrorSnackbar from '../MediaErrorSnackbar/MediaErrorSnackbar';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import Video from 'twilio-video';

import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';

import useVideoDetails from '../../../hooks/useVideoDetails';

import { VscClose } from 'react-icons/vsc';
import { FcVideoCall } from 'react-icons/fc';

import moment from 'moment';
import { RoomNameCharsInvalidError } from 'twilio-video';
import { AnyMxRecord } from 'dns';
import tokenGenerator from '../../../utils/token';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    width: '100%',
    border: '2px solid #aaa',
    margin: '1em 0',
  },
  localPreviewContainer: {
    paddingRight: '2em',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2.5em',
    },
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      '& button': {
        margin: '0.5em 0',
      },
    },
  },
  mobileButtonBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1.5em 0 1em',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
}));

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  setStep: (step: Steps) => void;
}

export default function DeviceSelectionScreen({ name, roomName, setStep }: DeviceSelectionScreenProps, props: any) {
  const classes = useStyles();
  const { getToken, isFetching } = useAppState();

  const { getAudioAndVideoTracks } = useVideoContext();
  const { connect, isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;

  const [mediaError, setMediaError] = useState<Error>();

  const [step] = useState(Steps.roomNameStep);
  useEffect(() => {
    // console.log('USE EFFECT');
    // console.log('details.test ==> ' + details.test);
    // //details.test = false;
    // console.log('details.test 2 ==> ' + details.test);
    //console.log(getAudioAndVideoTracks);

    getAudioAndVideoTracks().catch(error => {
      console.log('Error acquiring local media:');
      console.dir(error);
      setMediaError(error);
    });
  }, [getAudioAndVideoTracks, step]);

  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const videoTrack = localTracks.find(track => track.name.includes('camera')) as LocalVideoTrack;
  const audioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;

  console.log('isAudioEnabled ==> ' + isAudioEnabled);
  console.log('isVideoEnabled ==> ' + isVideoEnabled);
  console.log('step ==> ' + step);

  const toggleAudioVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 100) {
      lastClickTimeRef.current = Date.now();
      if (isVideoEnabled) {
        toggleVideoEnabled();
      }
      if (isAudioEnabled) {
        toggleAudioEnabled();
      }
    }
  }, [toggleVideoEnabled]);

  const handleJoin = () => {
    /*const token = tokenGenerator(name, roomName);
    console.log(token);
    connect(token);*/

    /*
require('dotenv').config();

    console.log('HERE WE ARE GET');
    console.log(testtoken);

    const AccessToken = require('twilio').jwt.AccessToken;
    const VideoGrant = AccessToken.VideoGrant;
    // TWILIO_ACCOUNT_SID=AC468878b7c76d7645e5d8d5b9194f34c1
    // TWILIO_API_KEY_SID=SK1ad11445386d42677acc642b1d891431
    // TWILIO_API_KEY_SECRET=COdciglvDMAGBwKS8lgQPQxqu3SbkklF
    const MAX_ALLOWED_SESSION_DURATION = 14400;
    const twilioAccountSid = process.env.TWILIO_ACCOUNT_SID;
    const twilioApiKeySID = process.env.TWILIO_API_KEY_SID;
    const twilioApiKeySecret = process.env.TWILIO_API_KEY_SECRET;
    // Used when generating any kind of tokens
    // To set up environmental variables, see http://twil.io/secure
    /*const twilioAccountSid = 'AC468878b7c76d7645e5d8d5b9194f34c1';
    const twilioApiKey = 'SKbab43c53ea68b28bc185b7e68cc5488c';
    const twilioApiSecret = '5FsLVaXKSgqXGcxcVFLdLm72m4z9EptP';*/

    //  const identity = name;

    // Create Video Grant
    /*  const videoGrant = new VideoGrant({
      room: roomName,
    });
    console.log('name');
    console.log(twilioAccountSid);
*/
    // Create an access token which we will sign and return to the client,
    // containing the grant we just created
    /*  const token = new AccessToken(twilioAccountSid, twilioApiKeySID, twilioApiKeySecret, { identity: identity },{
      ttl: MAX_ALLOWED_SESSION_DURATION,
    });
    token.addGrant(videoGrant);
    console.log('name');
    console.log(name);
    console.log(roomName);
    console.log(token);
    const newtoken = token.toJwt();
    console.log(newtoken);*/
    //connect(newtoken);
    // Serialize the token to a JWT string
    //console.log(getToken(name, roomName).then(token => connect(token)));
    getToken(name, roomName).then(token => connect(token));
  };

  const details = useVideoDetails();
  console.log('details.test 2 ==> ' + details.test);
  details.test = false;
  console.log('details.test 3 ==> ' + details.test);

  const SubContent = (
    <>
      {Video.testPreflight && <PreflightTest />}
      <MediaErrorSnackbar error={mediaError} />
    </>
  );
  return (
    <>
      <Grid container justify="center">
        <Grid item md={7} sm={12} xs={12}>
          <div className={classes.localPreviewContainer}>
            <LocalVideoPreview identity={name} />
          </div>
          <div className={classes.mobileButtonBar}>
            <Hidden mdUp>
              <ToggleAudioButton className={classes.mobileButton} disabled={disableButtons} />
              <ToggleVideoButton className={classes.mobileButton} disabled={disableButtons} />
            </Hidden>
          </div>
        </Grid>
        <Grid item md={5} sm={12} xs={12}>
          <Grid container direction="column" justify="space-between" style={{ height: '100%' }}>
            <div className="videoMenuBar">
              <Hidden smDown>
                <SettingsMenu mobileButtonClass={classes.mobileButton} />
                <ToggleAudioButton className={classes.deviceButton} disabled={disableButtons} />
                <ToggleVideoButton className={classes.deviceButton} disabled={disableButtons} />
                <Link
                  to={{ pathname: `/event/${details.eventId}` }}
                  className="MuiButtonBase-root MuiButton-root MuiButton-text videoBtn"
                  onClick={toggleAudioVideo}
                >
                  <VscClose size={25} color={'#d93025'} />
                </Link>
                <Button className="videoBtn start" data-cy-join-now onClick={handleJoin} disabled={disableButtons}>
                  <FcVideoCall size={25} />
                  &nbsp;Commencer
                </Button>
              </Hidden>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
