import gql from 'graphql-tag';

export default gql(`
query($vendorID: ID!) {
  getVendor(id: $vendorID) {
    name
    website
    id
    maxusers
    maxduration
    style
    gap
  }
}`);
