import gql from 'graphql-tag';

export default gql(`
query ($email:String!) {
  listEvents (filter:  {
    and : {
     host: {eq: $email },
     status:{notContains:"Z"} 
    }
  }) {
    items {
      id
      host
      guestName
      guestEmail
      location
      eventDate
      status
      description
      roomID
    }
  }
}`);
