import React, { Component } from 'react';
import { graphql } from 'react-apollo';

import moment from 'moment';

import QueryGetEvent from '../graphql/QueryGetEvent';
import SubsriptionEventComments from '../graphql/SubsriptionEventComments';

import { Button } from '@material-ui/core';
import { IoMdContact } from 'react-icons/io';
import { MdClose } from 'react-icons/md';

import NewComment from './NewComment';

const stc = require('string-to-color');

class EventComments extends Component {
  subscription;

  componentDidMount() {
    this.subscription = this.props.subscribeToComments();
  }

  componentWillUnmount() {
    this.subscription();
  }

  renderComment = comment => {
    const now = moment.utc().format();
    const diff = moment.duration(moment(now).diff(moment(comment.createdAt)));
    var minutes = parseInt(diff.asMinutes());
    return (
      <div className="comment" key={comment.commentId} style={{ display: 'flex' }}>
        <div
          className="label"
          style={{
            color: '#fff',
            padding: '3px 10px',
            fontSize: 11,
            alignItems: 'center',
            justifyContent: 'center',
            height: 'min-content',
            backgroundColor: `${stc(comment.name)}`,
          }}
        >
          {comment.name}
        </div>
        <div style={{ padding: '0 30px 10px 10px' }}>
          <div className="text">{comment.content}</div>
          {minutes >= 180 && (
            <div style={{ color: '#888', fontSize: 12, paddingTop: 3 }}>
              {moment(comment.createdAt).format('Do MMM, HH:mm')}
            </div>
          )}
          {minutes > 20 && minutes < 180 && (
            <div style={{ color: '#888', fontSize: 12, paddingTop: 3 }}>
              {moment(comment.createdAt).format('HH:mm')}
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    const {
      comments: { items },
      eventId,
      user,
      name,
    } = this.props;
    console.log(user, name);

    return (
      <div style={{ padding: '20px 10px 10px 10px' }}>
        <NewComment eventId={eventId} user={user} name={name} />
        <div className="ui items" style={{ paddingTop: 25, marginTop: 21, borderTop: '1px solid #d2d2d2' }}>
          {[]
            .concat(items)
            .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
            .map(this.renderComment)}
        </div>
      </div>
    );
  }
}

const EventCommentsWithData = graphql(QueryGetEvent, {
  options: ({ eventId: id }) => ({
    fetchPolicy: 'cache-first',
    variables: { id },
  }),
  props: props => ({
    comments: props.data.getEvent ? props.data.getEvent.comments : { items: [] },
    subscribeToComments: () =>
      props.data.subscribeToMore({
        document: SubsriptionEventComments,
        variables: {
          eventId: props.ownProps.eventId,
        },
        updateQuery: (
          prev,
          {
            subscriptionData: {
              data: { subscribeToEventComments },
            },
          }
        ) => {
          const res = {
            ...prev,
            getEvent: {
              ...prev.getEvent,
              comments: {
                __typename: 'CommentConnections',
                ...prev.getEvent.comments,
                items: [
                  ...prev.getEvent.comments.items.filter(c => c.commentId !== subscribeToEventComments.commentId),
                  subscribeToEventComments,
                ],
              },
            },
          };
          return res;
        },
      }),
  }),
})(EventComments);

export default EventCommentsWithData;
