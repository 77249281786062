import gql from 'graphql-tag';

export default gql(`
mutation updadeTeam($email: ID! $status: Boolean) {
    updateTeam (
    input: {
        email: $email
        status: $status
      }
  ) {
    email
    name
    username
    vendorID
    profile
    location
    products
    status
  }
}`);
