import gql from 'graphql-tag';

export default gql(`
query GetTeam($email: ID!) {
  getTeam(email: $email) {
    email
    name
    username
    vendorID
    profile
    location
    products
    status
    enabled
    vendor {
      items {
        enabled
        customerdemos
        id
        maxduration
        maxusers
        name
        website
        style
      }
    }
  }
}
`);
