import React, { Component } from 'react';
import { graphql, compose, withApollo } from 'react-apollo';

import moment from 'moment';

import { Divider, Dialog, Button, DialogTitle, DialogActions, TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { MdAddCircleOutline } from 'react-icons/md';
import { IoMdClose, IoIosArrowForward } from 'react-icons/io';

import { withStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';

import MutationProductOnEvent from '../graphql/MutationProductOnEvent';
import QueryGetEvent from '../graphql/QueryGetEvent';

import SubsriptionProductsUpdate from '../graphql/SubsriptionProductsUpdate';
import MutationDeleteProduct from '../graphql/MutationDeleteProduct';

const styles = theme => ({
  productsCard: {},
  input: {
    border: '1px solid #d2d2d2',
    padding: '10px 15px',
    borderRadius: 5,
    '& .MuiTextField-root': {
      margin: 0,
      border: 0,
    },
  },
  btn: {
    backgroundColor: '#ff6600',
    color: '#fff',
    marginLeft: 15,
    '&:hover': {
      color: '#666',
    },
  },
  removeBtn: {
    position: 'absolute',
    top: 10,
    left: -10,
    minWidth: '10px !important',
    background: '#d2d2d2d4',
    borderRadius: 14,
    padding: 7,
  },
  btnCancel: {
    backgroundColor: '#dD2d2D2',
    color: '#777',
    '&:hover': {
      color: '#666',
    },
  },
});

class EventProducts extends Component {
  static defaultProps = {
    products: [],
    handleRemove: () => null,
    handleSubmit: () => null,
    createProduct: () => null,
  };

  static defaultState = {
    product: {
      url: '',
    },
    loading: false,
    showProductModal: false,
    error: null,
  };

  state = EventProducts.defaultState;

  /*subscription;

    componentDidMount() {
        this.subscription = this.props.subscribeToProducts();
    }

    componentWillUnmount() {
        this.subscription();
    }
    */

  handleRemove = async (product, e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ loading: true });

    const { deleteProduct } = this.props;
    await deleteProduct(product);
    this.setState(EventProducts.defaultState);
  };

  handleSubmit = async e => {
    e.stopPropagation();
    e.preventDefault();
    const { product } = this.state;
    const { eventId, website, createProduct, vendorID } = this.props;
    const { client } = this.props;

    this.setState({ loading: true });

    if (product.url.includes(website)) {
      await createProduct({
        ...product,
        eventId,
        lookup: true,
        vendorID: vendorID,
        createdAt: moment.utc().format(),
      });

      setTimeout(
        async function() {
          await client.query({
            query: QueryGetEvent,
            variables: { id: eventId },
            fetchPolicy: 'network-only',
          });
          this.setState(EventProducts.defaultState);
        }.bind(this),
        4000
      );
    } else {
      this.setState({ error: `Merci de saisir une page du site ${website}` });
      this.setState({ loading: false });
    }
  };

  handleChange = ({ target: { value: url } }) => {
    this.setState({ product: { url } });
    this.setState({ error: null });
  };

  renderProduct = product => {
    const { classes } = this.props;
    return (
      <div
        className="product"
        key={product.id}
        style={{ marginTop: 20, paddingTop: 20, borderTop: '1px solid #d2d2d2', display: 'flex', position: 'relative' }}
      >
        <div className="productRemove">
          <Button onClick={this.handleRemove.bind(this, product)} className={classes.removeBtn}>
            <IoMdClose size={15} />
          </Button>
        </div>
        <div className="productImage" style={{ maxWidth: '35%' }}>
          <img src={product.image} width="100%" />
        </div>
        <div
          className="productDesc"
          style={{ padding: '10px 15% 10px 20px', justifyContent: 'space-between', display: 'flex' }}
        >
          <div className="text" style={{ fontWeight: 'bold', paddingRight: 15 }}>
            {product.name}
          </div>
          <div style={{ position: 'absolute', right: 0, top: '50%' }}>
            <Button
              onClick={e => {
                e.preventDefault();
                window.open(product.url);
              }}
              style={{ color: '#333' }}
            >
              <IoIosArrowForward size={25} />
            </Button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      products: { items },
      eventId,
      classes,
    } = this.props;
    const { product, loading, showProductModal, error } = this.state;

    return (
      <div className={classes.productsCard}>
        <div
          style={{
            display: 'flex',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: 5,
          }}
        >
          <div style={{ fontSize: 16, fontWeight: '600' }}>Sélection Live Shopping</div>
          <Button onClick={() => this.setState({ showProductModal: true })}>
            <MdAddCircleOutline size={30} color="#ff6600" />
          </Button>
        </div>
        <div className="ui items">
          {[]
            .concat(items)
            .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
            .map(this.renderProduct)}
        </div>
        <Dialog
          open={showProductModal}
          onClose={() => this.setState({ showProductModal: !showProductModal })}
          className="HistoryModal"
        >
          <IconButton
            aria-label="close"
            style={{ width: 50, height: 50, position: 'absolute', right: 20 }}
            onClick={() => this.setState({ showProductModal: false })}
          >
            <CloseIcon />
          </IconButton>
          <div style={{ textAlign: 'left', paddingTop: 15, paddingBottom: 15, fontSize: 16 }}>Ajouter un produit</div>
          <Divider />
          <TextField
            autoFocus
            margin="dense"
            id="URL"
            label="Copier/coller l'URL du produit"
            type="text"
            value={product.url}
            onChange={this.handleChange}
            fullWidth
            style={{ minWidth: 350, marginTop: 30, fontSize: 13, paddingBottom: 15 }}
          />
          <div style={{ color: 'red', paddingTop: 10, paddingBottom: 20 }}>{error}</div>
          <DialogActions>
            <Button
              onClick={() => this.setState({ showProductModal: !showProductModal })}
              color="primary"
              variant="outlined"
            >
              Annuler
            </Button>
            <Button onClick={this.handleSubmit.bind(this)} color="primary" variant="outlined" disabled={loading}>
              {loading ? <CircularProgress size={23} /> : 'Valider'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const EventProductsWithData = withApollo(
  compose(
    graphql(QueryGetEvent, {
      options: props => ({
        variables: { id: props.eventId },
        fetchPolicy: 'network-only', //'cache-first',
      }),
      props: props => ({
        products: props.data.getEvent ? props.data.getEvent.products : { items: [] },
        subscribeToProducts: () =>
          props.data.subscribeToMore({
            document: SubsriptionProductsUpdate,
            variables: {
              eventId: props.eventId,
            },
            updateQuery: (
              prev,
              {
                subscriptionData: {
                  data: { SubsriptionProductsUpdate },
                },
              }
            ) => {
              const res = {
                ...prev,
                getEvent: {
                  ...prev.getEvent,
                  products: {
                    __typename: 'ProductConnection',
                    ...prev.getEvent.SubsriptionProductsUpdate,
                    items: [
                      ...prev.getEvent.products.items, //.filter(c => c.id !== SubsriptionProductsUpdate.id),
                      SubsriptionProductsUpdate,
                    ],
                  },
                },
              };

              return res;
            },
          }),
      }),
    }),
    graphql(MutationDeleteProduct, {
      options: props => {
        return {
          refetchQueries: [
            {
              query: QueryGetEvent,
              variables: { id: props.eventId },
            },
          ],
        };
      },
      props: props => ({
        deleteProduct: product => {
          return props.mutate({
            variables: { id: product.id },
            optimisticResponse: () => ({
              deleteProduct: {
                ...product,
                __typename: 'Product',
              },
            }),
          });
        },
      }),
    }),
    graphql(MutationProductOnEvent, {
      options: props => {
        return {
          refetchQueries: [
            {
              query: QueryGetEvent,
              variables: { id: props.eventId },
            },
          ],
        };
      },
      props: props => ({
        createProduct: product => {
          return props.mutate({
            variables: { ...product },
            optimisticResponse: { productOnEvent: { ...product, __typename: 'Product' } },
          });
        },
      }),
    })
  )(EventProducts)
);

export default withStyles(styles)(EventProductsWithData);
