import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Router, browserHistory } from 'react-router';

import { graphql, compose, withApollo } from 'react-apollo';

import QueryTeam from '../graphql/QueryTeam';
import QueryGetMember from '../graphql/QueryGetMember';
import MutationCreateTeam from '../graphql/MutationCreateTeam';

import moment from 'moment';

import { Auth } from 'aws-amplify';

function getUser() {
  let user = Auth.user.attributes['email'];
  return user;
}

class NewMember extends Component {
  static defaultProps = {
    createTeam: () => null,
  };

  state = {
    member: {
      email: '',
      name: '',
      username: '',
      products: '',
      location: '',
      profile: 'Admin',
      status: false,
      enabled: true,
    },
  };

  handleChange(field, { target: { value } }) {
    const { member } = this.state;
    member[field] = value;
    this.setState({ member });
  }

  handleDateChange(field, value) {
    this.handleChange(field, { target: { value: value.format() } });
  }

  checkEmail = email => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emailValid = re.test(email);
    return emailValid;
  };

  memberExists = async email => {
    const { client } = this.props;
    const query = QueryGetMember;
    const userExists = await client.query({
      query,
      variables: { email: email },
      fetchPolicy: 'network-only',
    });
    if (typeof userExists.data.getTeam !== 'undefined' && userExists.data.getTeam != null) {
      return true;
    } else {
      return false;
    }
  };

  handleSave = async e => {
    e.stopPropagation();
    e.preventDefault();
    const { createTeam } = this.props;
    const { member } = this.state;
    const { client } = this.props;

    const validEmail = this.checkEmail(member.email);
    const validUsername = member.username.length > 2 ? true : false;
    const validName = member.name.length > 2 ? true : false;

    if (validEmail && validUsername && validName) {
      const exist = await this.memberExists(member.email);
      if (exist) {
        window.confirm(`Un utilisateur avec l'email ${member.email} existe déjà`);
      } else {
        const query = QueryGetMember;
        const email = getUser();
        const adminQL = await client.query({
          query,
          variables: { email: email },
          fetchPolicy: 'network-only',
        });

        const extraData = {
          vendorID: adminQL.data.getTeam.vendorID,
          createdAt: moment.utc().format(),
        };
        await createTeam({ ...member, ...extraData });
        window.confirm(
          `Le nouvel utilisateur ${member.username} a bien été créé. Il va recevoir un email pour activer son compte et choisir son mot de passe.`
        );

        browserHistory.push('/profile');
      }
    } else {
      const errorMessage = !validEmail
        ? "Merci d'indiquer un email valide"
        : !validUsername
        ? "Merci d'indiquer un username valide"
        : "Merci d'indiquer un nom valide";
      window.confirm(errorMessage);
    }
  };

  render() {
    const { member } = this.state;

    return (
      <div className="ui container raised very padded segment">
        <h1 className="ui header">Ajouter un membre</h1>
        <div className="ui form">
          <div className="field eight wide">
            <label htmlFor="name">Nom</label>
            <input type="text" id="name" value={member.name} onChange={this.handleChange.bind(this, 'name')} />
          </div>
          <div className="field eight wide">
            <label htmlFor="name">Username</label>
            <input
              type="text"
              id="username"
              value={member.username}
              onChange={this.handleChange.bind(this, 'username')}
            />
          </div>
          <div className="field eight wide">
            <label htmlFor="name">Email</label>
            <input type="text" id="email" value={member.email} onChange={this.handleChange.bind(this, 'email')} />
          </div>
          <div className="field eight wide">
            <label htmlFor="profil">Profil</label>
            <div className="formfield-select--container">
              <select defaultValue={member.profile} onChange={this.handleChange.bind(this, 'profile')}>
                <option value="Admin">Admin</option>
                <option value="Vendeur">Vendeur</option>
                <option value="Client">Client</option>
              </select>
            </div>
          </div>
          <div className="field eight wide">
            <label htmlFor="where">Lieu</label>
            <input
              type="text"
              id="location"
              value={member.location}
              onChange={this.handleChange.bind(this, 'location')}
            />
          </div>
          <div className="field eight wide">
            <label htmlFor="product">Produits</label>
            <input
              type="text"
              id="products"
              value={member.products}
              onChange={this.handleChange.bind(this, 'products')}
            />
          </div>
          <div className="ui buttons">
            <Link to="/profile" className="ui button">
              Annuler
            </Link>
            <div className="or"></div>
            <button className="ui positive button" onClick={this.handleSave}>
              Valider
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withApollo(
  compose(
    graphql(MutationCreateTeam, {
      props: props => ({
        createTeam: member => {
          return props.mutate({
            variables: member,
            optimisticResponse: () => ({
              createTeam: {
                ...member,
                __typename: 'Team',
              },
            }),
          });
        },
      }),
    })
  )(NewMember)
);
