import React, { useState, useContext } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { SiGooglecalendar, SiGooglehangoutsmeet, SiAddthis, SiGoogleanalytics } from 'react-icons/si';
import { MdAccountCircle, MdClose, MdDashboard } from 'react-icons/md';
import { IoMdListBox } from 'react-icons/io';
import 'semantic-ui-css/semantic.min.css';
import 'react-datepicker/dist/react-datepicker.css';

import { ApolloProvider } from 'react-apollo';
import AWSAppSyncClient, { defaultDataIdFromObject } from 'aws-appsync';
import { Rehydrated } from 'aws-appsync-react';

import AllEvents from './components/AllEvents';
import Calendar from './components/Calendar';
import FullCalendar from './components/FullCalendar';
import Book from './components/Book';
import EventsToCome from './components/EventsToCome';
import ViewEvent from './components/ViewEvent';
import NewEvent from './components/NewEvent';
import NewTeamMember from './components/NewTeamMember';
import ViewMember from './components/ViewTeamMember';
import Profile from './components/ViewProfile';
import Video from './components/Video';
import JoinVisio from './components/JoinVisio';
import ViewAnalytics from './components/ViewAnalytics';

import Amplify, { Auth, I18n } from 'aws-amplify';

import {
  withAuthenticator,
  AmplifyForgotPassword,
  AmplifySignOut,
  AmplifySignIn,
  AmplifyAuthenticator,
} from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

import '@aws-amplify/ui/dist/style.css';
import './App.css';
import appSyncConfig from './aws-exports';

Amplify.configure(appSyncConfig);

const federated = {
  facebookAppId: '1075543349315886',
  googleClientId: 'test',
};

const MyTheme = {
  googleSignInButton: { backgroundColor: 'red', borderColor: 'red' },
  button: { backgroundColor: 'green', borderColor: 'red' },
  signInButtonIcon: { display: 'none' },
};

const Logo = () => {
  return (
    <div className="logo">
      <img src={logo} width="120px" />
    </div>
  );
};

// You can get the current config object
const currentConfig = Auth.configure();

const MyContext = React.createContext();

const MyProvider = props => {
  const [menuOpenState, setMenuOpenState] = useState(true);

  return (
    <MyContext.Provider
      value={{
        isMenuOpen: menuOpenState,
        toggleMenu: () => setMenuOpenState(!menuOpenState),
        stateChangeHandler: newState => setMenuOpenState(newState.isOpen),
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
};

const Button = () => {
  const ctx = useContext(MyContext);
  return (
    <div className="btn-toggle" onClick={ctx.toggleMenu}>
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 448 512"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
      </svg>
    </div>
  );
};

const authScreenLabels = {
  fr: {
    'Sign In': 'Connexion',
    'Sign in to your account': 'Connectez vous à votre compte',
    'Enter your username': 'Saisissez votre email',
    'Enter your password': 'Indiquez votre mot de passe',
    'Username *': 'Votre email',
    'Password *': 'Votre mot de passe',
    'Sign Up Account': 'Create a new account',
    'Forgot your password?': 'Mot de passe oublié ?',
    'Reset password': 'Réinitialisée votre mot de passe',
    'Sign in with Google': 'Connectez-vous avec Google',
    'Sign in with Facebook': 'Connectez-vous avec Facebook',
    or: 'ou',
    'Change password': 'Saisissez un nouveau mot de passe',
    'New password': 'Nouveau mot de passe',
    'Full Name': "Choisissez un nom d'utilisateur",
    Change: 'Valider',
    'Back to Sign In': 'Retour',
    'Reset your password': 'Réinitialisez votre mot de passe',
    'Send code': 'Envoyer un code de vérification',
    'Incorrect username or password.': 'Email ou mot de passe incorrect',
  },
};

I18n.setLanguage('fr');
I18n.putVocabularies(authScreenLabels);

const Dashboard = () => (
  <div className="ui container">
    <EventsToCome />
  </div>
);

const logo = 'https://bleeker-web.s3.us-east-2.amazonaws.com/bleeker-new-logo.png';

const routes = [
  {
    path: '/',
    exact: true,
    sidebar: () => null,
    main: () => <Dashboard />,
  },
  {
    path: '/analytics',
    sidebar: () => null,
    main: () => <ViewAnalytics />,
  },
  {
    path: '/calendar',
    sidebar: () => null,
    main: () => <Calendar />,
  },
  {
    path: '/newmember',
    sidebar: () => null,
    main: () => <NewTeamMember />,
  },
  {
    path: '/fullcalendar',
    sidebar: () => null,
    main: () => <FullCalendar />,
  },
  {
    path: '/history',
    sidebar: () => null,
    main: () => <AllEvents />,
  },
  {
    path: '/visio',
    sidebar: () => null,
    main: () => <Video />,
  },
  {
    path: '/profile',
    sidebar: () => null,
    main: () => <Profile />,
  },
];

const App = () => {
  const ctx = useContext(MyContext);
  return (
    <Router>
      <div style={{ display: 'flex' }}>
        <div className={ctx.isMenuOpen ? 'sidebar toggled' : 'sidebar hide'}>
          <div className="sidebar-inner">
            <div className="btn-close" onClick={ctx.toggleMenu}>
              <MdClose />
            </div>
            <div className="sidebar-header">
              <div className="sidebar-title">Bleeker Console</div>
            </div>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              <li className="menu-item">
                <Link className="menu-inner-item" to="/">
                  <span className="menu-icon-wrapper">
                    <span className="menu-icon">
                      <MdDashboard />
                    </span>
                  </span>
                  Dashboard
                  <span className="menu-arrow-wrapper">
                    <span className="menu-arrow"></span>
                  </span>
                </Link>
              </li>
              <li className="menu-item">
                <Link className="menu-inner-item" to="/analytics">
                  <span className="menu-icon-wrapper">
                    <span className="menu-icon">
                      <SiGoogleanalytics />
                    </span>
                  </span>
                  Analytics
                  <span className="menu-arrow-wrapper">
                    <span className="menu-arrow"></span>
                  </span>
                </Link>
              </li>
              <li>
                <Link className="menu-inner-item" to="/calendar">
                  <span className="menu-icon-wrapper">
                    <span className="menu-icon">
                      <SiAddthis />
                    </span>
                  </span>
                  Ajouter un Rendez-Vous
                  <span className="menu-arrow-wrapper">
                    <span className="menu-arrow"></span>
                  </span>
                </Link>
              </li>
              <li>
                <Link className="menu-inner-item" to="/history">
                  <span className="menu-icon-wrapper">
                    <span className="menu-icon">
                      <IoMdListBox />
                    </span>
                  </span>
                  Historique
                  <span className="menu-arrow-wrapper">
                    <span className="menu-arrow"></span>
                  </span>
                </Link>
              </li>
              <li>
                <Link className="menu-inner-item" to="/fullcalendar">
                  <span className="menu-icon-wrapper">
                    <span className="menu-icon">
                      <SiGooglecalendar />
                    </span>
                  </span>
                  Calendrier
                  <span className="menu-arrow-wrapper">
                    <span className="menu-arrow"></span>
                  </span>
                </Link>
              </li>
              <li>
                <Link className="menu-inner-item" to="/visio">
                  <span className="menu-icon-wrapper">
                    <span className="menu-icon">
                      <SiGooglehangoutsmeet />
                    </span>
                  </span>
                  Visio
                  <span className="menu-arrow-wrapper">
                    <span className="menu-arrow"></span>
                  </span>
                </Link>
              </li>
              <li>
                <Link className="menu-inner-item" to="/profile">
                  <span className="menu-icon-wrapper">
                    <span className="menu-icon">
                      <MdAccountCircle />
                    </span>
                  </span>
                  Profile
                  <span className="menu-arrow-wrapper">
                    <span className="menu-arrow"></span>
                  </span>
                </Link>
              </li>
            </ul>
          </div>
          <Switch>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact} children={<route.sidebar />} />
            ))}
          </Switch>
        </div>

        <div style={{ flex: 1 }}>
          <Switch>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact} children={<route.main />} />
            ))}
            <Route path="/event/:id" component={ViewEvent} key={101} />
            <Route path="/member/:email" component={ViewMember} key={102} />
            <Route path="/book/:date" component={Book} key={103} />
            <Route path="/join/:id/:roomID/:name" render={props => <JoinVisio {...props} />} key={104} />
          </Switch>
        </div>
      </div>
    </Router>
  );
};

const client = new AWSAppSyncClient({
  url: appSyncConfig.aws_appsync_graphqlEndpoint,
  region: appSyncConfig.aws_appsync_region,
  auth: {
    type: appSyncConfig.aws_appsync_authenticationType,
    apiKey: appSyncConfig.aws_appsync_apiKey,
  },
  cacheOptions: {
    dataIdFromObject: obj => {
      let id = defaultDataIdFromObject(obj);

      if (!id) {
        const { __typename: typename } = obj;
        switch (typename) {
          case 'Comment':
            return `${typename}:${obj.commentId}`;
          default:
            return id;
        }
      }

      return id;
    },
  },
});

const AuthStateApp = () => {
  const ctx = useContext(MyContext);
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();
  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  return authState === AuthState.SignedIn && user ? (
    <ApolloProvider client={client}>
      <Rehydrated>
        <MyProvider>
          <div className="main-wrapper">
            <div className="capsule">
              <Button />
              <div className="greetings">Hello, {user.attributes['name']}</div>
              <AmplifySignOut />
            </div>
            <App />
          </div>
        </MyProvider>
      </Rehydrated>
    </ApolloProvider>
  ) : (
    <ApolloProvider client={client}>
      <Rehydrated>
        <div className="login">
          <Logo />
          <AmplifyAuthenticator>
            <AmplifySignIn
              //federated={federated}
              headerText="Connectez-vous à Bleeker"
              /*formFields={[
      {
      type: "email",
      label: null,
      placeholder: "Saisissez votre email",
      hint: "example@mail.com",
      required: true,
    },
    {
      type: "password",
      label: null,
      placeholder: "Indiquez votre mot de passe",
      hint: null,
      required: true,
    },
  ]}
*/
              slot="sign-in"
              hideSignUp
            ></AmplifySignIn>
          </AmplifyAuthenticator>
          <div className="copyright">© 2020 Bleeker</div>
        </div>
      </Rehydrated>
    </ApolloProvider>
  );
};

export default AuthStateApp;
