import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { graphql, compose, withApollo } from 'react-apollo';

import QueryAllMyEvents from '../graphql/QueryAllMyEvents';
import QueryAllVendorEvents from '../graphql/QueryAllVendorEvents';
import QueryGetMember from '../graphql/QueryGetMember';

import { Auth } from 'aws-amplify';

import EventStatus from '../utils/EventStatus';
import Rating from '@material-ui/lab/Rating';

import styled from 'styled-components';
import { useTable, useFilters } from 'react-table';

import moment from 'moment';
import { get } from 'lodash';

function getUser() {
  let user = {
    email: Auth.user.attributes['email'],
    vendorID: Auth.user.attributes['custom:vendorID'],
  };
  return user;
}

const Styles = styled.div`
  padding: 1rem;
  table {
    border-spacing: 0;
    border: 1px solid grey;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.7rem;
      border-bottom: 1px solid grey;
      border-right: 1px solid grey;
      :last-child {
        border-right: 0;
      }
    }
  }
`;
const columns = [
  {
    Header: 'Invité',
    accessor: d => {
      const guests = d.guests.items;
      const sponsor =
        guests.filter(x => x.sponsor == true).length > 0 ? guests.filter(x => x.sponsor == true)[0].name : d.guestName;
      return sponsor;
    },
    Filter: DefaultColumnFilter,
    filter: 'includes',
  },
  {
    Header: 'Date',
    Filter: DefaultColumnFilter,
    filter: 'includes',
    accessor: d => {
      return moment(d.eventDate).format('DD-MM-YYYY à HH:mm');
    },
  },
  {
    Header: 'Host',
    accessor: 'host',
    Filter: DefaultColumnFilter,
    filter: 'includes',
  },
  {
    Header: 'Status',
    accessor: 'status',
    Filter: SelectColumnFilter,
    filter: 'includes',
    Cell: e => {
      const result = EventStatus.find(data => data.value === e.value);
      return result['label'];
    },
  },
  {
    Header: 'Evaluation',
    Filter: SelectColumnFilter,
    disableFilters: true,
    accessor: d => {
      const arrayRatings = d.ratings.items.filter(e => e.user !== d.host);
      const averageRating =
        arrayRatings.length > 0
          ? arrayRatings.reduce((total, next) => total + next.rating, 0) / arrayRatings.length
          : null;
      return <Rating name="simple-controlled" value={averageRating} size="small" precision={0.25} readOnly />;
    },
  },
  {
    Header: 'Details',
    accessor: 'id',
    id: 'links',
    disableFilters: true,
    Cell: e => <Link to={{ pathname: `/event/${e.value}` }}>Plus d'info</Link>,
  },
];

// Define a default UI for filtering
function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({ column: { filterValue = [], preFilteredRows, setFilter, id } }) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <input
        value={filterValue[0] || ''}
        type="number"
        onChange={e => {
          const val = e.target.value;
          setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: '70px',
          marginRight: '0.5rem',
        }}
      />
      to
      <input
        value={filterValue[1] || ''}
        type="number"
        onChange={e => {
          const val = e.target.value;
          setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: '70px',
          marginLeft: '0.5rem',
        }}
      />
    </div>
  );
}

function Table({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, visibleColumns } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters // useFilters!
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  const firstPageRows = rows.slice(0, 10);

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                  {/* Render the columns filter UI */}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {firstPageRows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />
      <div>Showing the first 20 results of {rows.length} rows</div>
      <div></div>
    </>
  );
}

class AllEvents extends Component {
  state = {
    busy: false,
  };

  static defaultProps = {
    events: [],
    deleteEvent: () => null,
  };

  async handleDeleteClick(event, e) {
    e.preventDefault();

    if (window.confirm(`Are you sure you want to delete event with ${event.eventGuest}`)) {
      const { deleteEvent } = this.props;

      await deleteEvent(event);
      await this.handleSync();
    }
  }

  componentDidMount() {
    console.log(getUser());
  }
  handleSync = async () => {
    const { client } = this.props;
    const query = QueryAllMyEvents;
    const email = getUser();
    this.setState({ busy: true });
    await client.query({
      query,
      variables: { email: email },
      fetchPolicy: 'network-only',
    });

    this.setState({ busy: false });
  };

  render() {
    const { busy } = this.state;
    const { member, userevents, vendorevents } = this.props;
    const events = member ? (member.profile.includes('dmin') ? vendorevents : userevents) : userevents;
    console.log(userevents, vendorevents);
    return (
      <div className="ui container raised very padded segment">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h1 className="pageTitle" style={{ display: 'block', padding: '30px' }}>
            Historique
            <br />
            Live Shopping.
          </h1>
          <button className="ui icon left basic button" onClick={this.handleSync} disabled={busy}>
            <i aria-hidden="true" className={`refresh icon ${busy && 'loading'}`}></i>
            Mettre à jour
          </button>
        </div>
        <div className="ui link cards" style={{ padding: 25 }}>
          <Styles>
            <Table columns={columns} data={[].concat(events).sort((a, b) => b.eventDate.localeCompare(a.eventDate))} />
          </Styles>
        </div>
      </div>
    );
  }
}

export default withApollo(
  compose(
    graphql(QueryGetMember, {
      options: () => {
        const user = getUser();
        return {
          variables: { email: user.email },
          fetchPolicy: 'network-only',
        };
      },
      props: ({ data: { getTeam: member, loading } }) => ({
        member,
        loading,
      }),
    }),
    graphql(QueryAllVendorEvents, {
      options: () => {
        const user = getUser();
        return {
          variables: { vendorID: user.vendorID },
          fetchPolicy: 'network-only',
        };
      },
      props: ({ data: { listEvents = { items: [] } } }) => ({
        vendorevents: listEvents.items,
      }),
    }),
    graphql(QueryAllMyEvents, {
      options: () => {
        const user = getUser();
        return {
          variables: { email: user.email },
          fetchPolicy: 'network-only',
        };
      },
      props: ({ data: { listEvents = { items: [] } } }) => ({
        userevents: listEvents.items,
      }),
    })
  )(AllEvents)
);
