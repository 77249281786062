import gql from 'graphql-tag';

export default gql(`
mutation CreateCalendar($email: String!, $day: String, $end: String, $start: String, $isOpen : Boolean, $idDay: Int, $vendorID : String) {
    createCalendar(input: {
      email : $email
      day : $day
      end : $end
      start : $start
      isOpen : $isOpen
      idDay : $idDay
      vendorID : $vendorID
    }
  ) {
      id
      email
      day
      end
      start
      isOpen
      idDay
      vendorID
    }
  }
`);
