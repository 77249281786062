import gql from 'graphql-tag';

export default gql(`
mutation MutationProductOnEvent($eventId: String! $url: String! $createdAt: String! $lookup: Boolean $vendorID: String) {
  productOnEvent(
    eventId: $eventId
    url: $url
    createdAt: $createdAt
    lookup: $lookup
    vendorID: $vendorID
  ) {
    eventId
    url
    createdAt
    image
    name
    id
    lookup
  }
}`);
