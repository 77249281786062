import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose, withApollo } from 'react-apollo';

import { Link } from 'react-router-dom';

import EventProducts from './EventProducts';

import QueryGetCalendar from '../graphql/QueryGetCalendar';
import QueryDailyCalendars from '../graphql/QueryDailyCalendars';
import QueryDailyVendorEvents from '../graphql/QueryDailyVendorEvents';
import QueryGetMember from '../graphql/QueryGetMember';
import MutationCreateEvent from '../graphql/MutationCreateEvent';
import MutationCreateGuests from '../graphql/MutationCreateGuests';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { FcCalendar } from 'react-icons/fc';
import { AiOutlineUser, AiOutlineMail, AiOutlineFieldTime, AiOutlinePhone } from 'react-icons/ai';
import { GoCommentDiscussion } from 'react-icons/go';

import Amplify, { API, Auth, graphqlOperation } from 'aws-amplify';

import { AuthState } from '@aws-amplify/ui-components';

import { v4 as uuid } from 'uuid';

import { Button, Dialog } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

import { withStyles } from '@material-ui/core/styles';

import moment from 'moment';
import 'moment/locale/fr';
import QueryGetVendor from '../graphql/QueryGetVendor';
moment.locale('fr');

function getUser() {
  let user = Auth.user.attributes['email'];
  return user;
}

const START_HOUR = 9;
const END_HOUR = 20;
const NB_WEEK_DAYS = 7;
const SLOT_DURATION = 20;

const styles = theme => ({
  input: {
    border: '1px solid #d2d2d2',
    padding: '10px 15px',
    borderRadius: 5,
    '& .MuiTextField-root': {
      margin: 0,
      border: 0,
    },
  },
  date_title: {
    display: 'flex',
    clear: 'both',
    textAlign: 'left',
    fontSize: 16,
    fontWeight: '600',
    textTransform: 'capitalize',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    height: '100%',
    padding: '10px 0px',
    margin: '70px 0 30px 0',
    color: '#152638',
    fontFamily: 'ProximaNova,sans-serif',
  },
  header_day: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    position: 'relative',
    padding: '10px 3px',
    margin: '0 5px 0 0',
    fontWeight: '600',
    fontSize: 20,
    textTransform: 'capitalize',
    color: '#152638',
    fontFamily: 'ProximaNova,sans-serif',
  },
  header_date: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    position: 'relative',
    padding: '10px 3px',
    margin: '0 5px 15px 0',
    //fontWeight:'600',
    fontSize: 18,
    textTransform: 'capitalize',
    color: '#152638',
    fontFamily: 'ProximaNova,sans-serif',
  },
  eventCard: {
    display: 'grid',
    margin: 0,
    width: 'calc(50% - 30px)',
    clear: 'both',
    float: 'left',
    border: 0,
    padding: 0,
    border: 0,
    borderRadius: 10,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: '0px 20px',
      border: 0,
    },
  },
});

class Book extends Component {
  constructor(props) {
    super(props);
    this.state = {
      week_index: 0,
      authState: 'undefined',
      host: 'to be defined',
      asGuest: false,
      events: [],
      calendars: [],
      arraySlot: [],
      selectedSlot: 'undefined',
      needUpdate: false,
      showModal: false,
      user: {
        name: '',
        email: '',
        phone: '',
      },
      event: {
        id: uuid(),
        guestName: '',
        guestEmail: '',
        eventDate: this.props.match.params.date,
        description: '',
        createdAt: moment.utc().format(),
        status: 'C1',
        products: [],
      },
      website: '',
      maxduration: '',
      maxguests: '',
    };
    this.initialState = { ...this.state };
  }

  static defaultProps = {
    createEvent: () => null,
  };

  handleChange(field, { target: { value } }) {
    const { user } = this.state;
    user[field] = value;
    this.setState({ user });
  }

  handleChangeDesc(field, { target: { value } }) {
    const { event } = this.state;
    event[field] = value;
    this.setState({ event });
  }

  setGuest = asGuest => {
    const { member } = this.props;
    this.setState({
      event: {
        ...this.state.event,
      },
      asGuest: asGuest,
    });
  };

  componentDidMount = async () => {
    this.setState({ authState: this.props.authState });
    this.setGuest(false);
  };

  handleSave = async e => {
    e.stopPropagation();
    e.preventDefault();

    await this.getEvents();
    await this.getCalendars();

    const { createEvent } = this.props;
    const { event, asGuest, user } = this.state;

    const stillOK = await this.freshCalendar();

    if (stillOK) {
      await this.getVendor();

      const validEmail = this.checkEmail(user.email);
      const validUsername = user.name.length > 2 ? true : false;

      if (validEmail && validUsername) {
        const member = {
          host: asGuest ? this.state.host : this.props.member.email,
          vendorID: this.state.vendorID,
          location: this.state.vendor.location,
          maxguests: this.state.vendor.maxusers,
          maxduration: this.state.vendor.maxduration,
        };
        await createEvent({ ...member, ...event });

        let guests = [];
        guests.push({
          id: uuid(),
          email: user.email,
          name: user.name,
          phone: user.phone,
          eventId: event.id,
          createdAt: event.createdAt,
          sponsor: true,
          onboard: true,
        });
        console.log(guests);
        const batchGuests = await API.graphql(graphqlOperation(MutationCreateGuests, { guests: guests }));

        this.setState({ showModal: true });
      } else {
        const errorMessage = !validEmail
          ? "Merci d'indiquer un email valide"
          : "Merci d'indiquer un nom d'invité valide";
        window.confirm(errorMessage);
      }
    } else {
      const errorMessage =
        "Le créneau que vous avez choisi n'est malheuresement plus disponible. Nous vous inivtons à choisir un autre moment pour votre Live Shopping.";
      window.confirm(errorMessage);
    }
  };

  getVendor = async () => {
    const { member, client } = this.props;
    const result = await client.query({
      query: QueryGetVendor,
      variables: { vendorID: this.state.vendorID },
      fetchPolicy: 'network-only',
    });
    this.setState({
      vendor: result.data.getVendor,
    });
  };

  getEvents = async () => {
    const { member, client } = this.props;
    const result = await client.query({
      query: QueryDailyVendorEvents,
      variables: { vendorID: member.vendorID, date: this.props.match.params.date },
      fetchPolicy: 'network-only',
    });
    this.setState({
      events: result.data.listEvents.items,
    });
  };

  getCalendars = async () => {
    const { member, client } = this.props;
    const result = await client.query({
      query: QueryDailyCalendars,
      variables: { vendorID: member.vendorID, idDay: moment(this.props.match.params.date).isoWeekday() },
      fetchPolicy: 'network-only',
    });
    this.setState({
      calendars: result.data.listCalendars.items,
    });
  };

  freshCalendar() {
    const { events, calendars } = this.state;
    var INIT_DATE = this.props.match.params.date;

    /* initialisation slot disponibilités */
    let arrayWeekSlots = [];

    {
      calendars &&
        calendars.forEach((row, index) => {
          var DAY = row.idDay;

          var row_date = moment(INIT_DATE).format();
          var row_date_start = moment(row_date)
            .startOf('day')
            .format();

          var slot_start = 60 * parseInt(row.start.slice(0, 2)) + parseInt(row.start.slice(3, 5)); //converti en minutes le début de la plage
          var slot_end = 60 * parseInt(row.end.slice(0, 2)) + parseInt(row.end.slice(3, 5)) - SLOT_DURATION + 1; //converti en minutes le fin de la plage

          let start_hour = moment(row_date_start)
            .add(slot_start, 'm')
            .format();
          let end_hour = moment(row_date_start)
            .add(slot_end, 'm')
            .format();

          const diff = moment.duration(moment(end_hour).diff(moment(start_hour)));
          var diff_minutes = parseInt(diff.asMinutes());

          for (let i = 0; i < diff_minutes; i += SLOT_DURATION) {
            var slot = moment(start_hour)
              .add(i, 'm')
              .format();
            var diff_ = moment.duration(moment(slot).diff(moment()));
            if (diff_ > 0) {
              arrayWeekSlots.push({
                idDay: DAY,
                title: row.day,
                date: slot,
                host: row.email,
                hour: moment(start_hour)
                  .add(i, 'm')
                  .format('HH'),
                time: moment(start_hour)
                  .add(i, 'm')
                  .format('HH:mm'),
                vendorID: row.vendorID,
                available: true,
              });
            }
          }
        });
    }

    /* Suppression des plages déjà occupés (events) */

    var arrayAvailable = arrayWeekSlots.filter(
      n => !events.some(n2 => n.host == n2.host && moment(n.date).format() == moment(n2.eventDate).format())
    );

    var confirmedSlots = arrayAvailable.filter(
      x =>
        moment(x.date)
          .startOf('hour')
          .format() ==
        moment(INIT_DATE)
          .startOf('hour')
          .format()
    );

    if (confirmedSlots.length > 0) {
      console.log(confirmedSlots[0].host);
      this.setState({ host: confirmedSlots[0].host });
      this.setState({ vendorID: confirmedSlots[0].vendorID });

      return true;
    } else {
      return false;
    }
  }

  static defaultProps = {
    updateStatus: () => null,
  };

  checkEmail = email => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emailValid = re.test(email);
    return emailValid;
  };

  render() {
    const { showModal, asGuest, authState, event, user } = this.state;
    const { classes, member } = this.props;
    const date = this.props.match.params.date;
    return (
      <div className="ui container raised very padded segment">
        <div className="content">
          <Link to="/calendar" className="ui button">
            Retour
          </Link>
          <div style={{ margin: '30px 0' }}>
            <div className={classes.eventCard}>
              <div className="field eight wide" style={{ marginTop: 50 }}>
                <InputBase
                  id="date"
                  className={classes.input}
                  style={{ fontWeight: '600' }}
                  value={moment(date).format('dddd, Do MMMM à HH:mm')}
                  readOnly={true}
                  fullWidth
                  startAdornment={
                    <InputAdornment position="start">
                      <FcCalendar size={30} />
                    </InputAdornment>
                  }
                />

                <div className="field eight wide" style={{ marginTop: 20 }}>
                  <InputBase
                    id="name"
                    className={classes.input}
                    value={user.name}
                    onChange={this.handleChange.bind(this, 'name')}
                    readOnly={asGuest ? true : false}
                    fullWidth
                    startAdornment={
                      <InputAdornment position="start">
                        <AiOutlineUser size={20} color={'#999'} />
                      </InputAdornment>
                    }
                  />
                </div>

                <div className="field eight wide" style={{ marginTop: 20 }}>
                  <InputBase
                    id="phone"
                    className={classes.input}
                    value={user.phone}
                    onChange={this.handleChange.bind(this, 'phone')}
                    readOnly={asGuest ? true : false}
                    fullWidth
                    startAdornment={
                      <InputAdornment position="start">
                        <AiOutlinePhone size={20} color={'#999'} />
                      </InputAdornment>
                    }
                  />
                </div>

                <div className="field eight wide" style={{ marginTop: 20 }}>
                  <InputBase
                    id="email"
                    className={classes.input}
                    value={user.email}
                    onChange={this.handleChange.bind(this, 'email')}
                    readOnly={asGuest ? true : false}
                    fullWidth
                    startAdornment={
                      <InputAdornment position="start">
                        <AiOutlineMail size={20} color={'#999'} />
                      </InputAdornment>
                    }
                  />
                </div>

                <div className="field eight wide" style={{ marginTop: 20 }}>
                  <InputBase
                    id="description"
                    className={classes.input}
                    value={event.description}
                    onChange={this.handleChangeDesc.bind(this, 'description')}
                    fullWidth
                    multiline={true}
                    rows={4}
                    startAdornment={
                      <InputAdornment position="start">
                        <GoCommentDiscussion size={20} color={'#999'} />
                      </InputAdornment>
                    }
                  />
                </div>

                {authState == AuthState.SignedIn ? (
                  <InputBase
                    id="username"
                    className={classes.input}
                    value={getUser()}
                    readOnly={true}
                    fullWidth
                    startAdornment={
                      <InputAdornment position="start">
                        <GoCommentDiscussion size={30} />
                      </InputAdornment>
                    }
                  />
                ) : (
                  <div></div>
                )}
              </div>

              <div className="ui buttons" style={{ marginTop: 50 }}>
                <Link to="/calendar" className="ui button">
                  Annuler
                </Link>
                <div className="or"></div>
                <button className="ui positive button" onClick={this.handleSave}>
                  Valider
                </button>
              </div>
            </div>

            <Dialog open={showModal} onClose={() => this.setState({ showModal: !showModal })} className="Modal">
              <IconButton
                aria-label="close"
                style={{ width: 50, height: 50, position: 'absolute', right: 20 }}
                onClick={() => this.setState({ showModal: false })}
              >
                <CloseIcon />
              </IconButton>
              <div style={{ textAlign: 'left', padding: '60px 30px', fontSize: 13 }}>
                <div>Votre sesssion Live Shopping est confirmée !</div>
                <div>
                  Rendez-vous {moment(date).format('dddd, Do MMMM à HH:mm')} pour une séance de Live Shopping. Vous
                  recevrez un email de confirmation avec le lien de connexion au Live Shopping.
                </div>
              </div>
              <div className="ui buttons">
                <Link to="/" className="ui button" color="primary" variant="outlined">
                  OK
                </Link>
              </div>
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}

const BookWithData = withApollo(
  compose(
    graphql(QueryGetMember, {
      options: () => {
        const email = getUser();
        return {
          variables: { email: email },
          fetchPolicy: 'no-cache',
        };
      },
      props: ({ data: { getTeam: member, loading } }) => ({
        member,
        loading,
      }),
    }),
    graphql(MutationCreateEvent, {
      props: props => ({
        createEvent: event => {
          return props.mutate({
            variables: event,
            optimisticResponse: () => ({
              createEvent: {
                ...event,
                __typename: 'Event',
              },
            }),
          });
        },
      }),
    })
  )(Book)
);

Book.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BookWithData);
