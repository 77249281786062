import React, { Component, createContext } from 'react';
import { graphql, compose, withApollo } from 'react-apollo';

import { Link } from 'react-router-dom';

import EventComments from './EventComments';
import EventProducts from './EventProducts';
import QueryGetEvent from '../graphql/QueryGetEvent';
import MutationUpdateEventStatus from '../graphql/MutationUpdateEventStatus';

import EventStatus from '../utils/EventStatus';
import useRoomState from '../hooks/useRoomState/useRoomState';

import { withStyles } from '@material-ui/core/styles';

import { Divider, Dialog, DialogContent, Button, DialogTitle } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

import { MdAddCircleOutline } from 'react-icons/md';
import { FcCalendar, FcVideoCall } from 'react-icons/fc';
import { BsPeopleCircle, BsPersonBoundingBox } from 'react-icons/bs';
import { FaYoutube } from 'react-icons/fa';
import { GoSettings } from 'react-icons/go';
import { RiInformationLine, RiHistoryFill, RiTimerLine } from 'react-icons/ri';
import { IoMdCloseCircle, IoIosCheckmarkCircle } from 'react-icons/io';
import { RiTimeLine } from 'react-icons/ri';
import { GrHistory } from 'react-icons/gr';

import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/fr';
moment.locale('fr');

const timezone = moment.tz.guess();
const offSet = parseInt(moment.tz(timezone).format('Z'));

const styles = theme => ({
  container: {
    width: '600px',
    minHeight: '400px',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 32px)',
    },
    '& .inputSelect': {
      width: 'calc(100% - 35px)',
    },
  },
  button: {
    float: 'right',
  },
  eventCard: {
    display: 'grid',
    margin: 0,
    width: 'calc(50% - 30px)',
    clear: 'both',
    float: 'left',
    border: 0,
    padding: '0px',
    border: '1px solid #d2d2d2',
    borderRadius: 10,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: '0px 20px',
      border: 0,
    },
  },
  paper: {},
  headline: {
    marginBottom: '1.3em',
    fontSize: '1.1rem',
  },
  listSection: {
    margin: '2em 0 0.8em',
    '&:first-child': {
      margin: '1em 0 2em 0',
    },
  },
  root: {
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

function RoomState(Component) {
  return function WrappedComponent(props) {
    const roomState = useRoomState();
    return <Component {...props} roomState={roomState} />;
  };
}

class ViewEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isFlushed: false,
    };
  }

  static defaultProps = {
    events: [],
    updateEvent: () => null,
  };

  addProduct = async url => {};

  async componentDidUpdate(nextProps) {
    if (!this.state.isFlushed && nextProps.location.state === 'flushDeal') {
      this.setState({ isFlushed: true });
      const { event } = this.props;
      const { updateEvent } = this.props;
      try {
        await updateEvent(event, 'F1');
      } catch (e) {}
    }
  }

  async handleChangeStatus(event, newstatus, e) {
    e.preventDefault();
    const { updateEvent } = this.props;
    await updateEvent(event, newstatus);
    window.confirm(`Le rendez-vous a été mis à jour.`);
  }

  render() {
    const { event, classes } = this.props;
    const roomState = this.props.roomState;
    const result = event ? EventStatus.find(data => data.value === event.status) : '00';
    const eventDate = event ? moment.utc(event.eventDate).format() : 'undefined'; //UTC date of event
    const now = moment.utc().format();
    const diff = moment.duration(moment(eventDate).diff(moment(now)));
    var minutes = parseInt(diff.asMinutes());
    const expired = minutes < -15 ? true : false;
    const soon = minutes < 120 && minutes > -15 ? true : false;
    const rawRatings = event ? event.ratings.items : [];
    const arrayRatings = rawRatings.filter(e => e.user !== event.host);
    const averageRating =
      arrayRatings.length > 0
        ? arrayRatings.reduce((total, next) => total + next.rating, 0) / arrayRatings.length
        : null;
    const sponsor = event ? event.guests.items.filter(x => x.sponsor == true)[0].name : '';
    const invite = event
      ? event.guests.items.length == 2
        ? ' et un autre invité'
        : event.guests.items.length > 2
        ? ` et ${event.guests.items.length - 1} autres invités`
        : null
      : null;
    return (
      <div className="ui container raised very padded segment">
        <Link to="/" className="ui button backto">
          Retour
        </Link>
        <h1 className="pageTitle" style={{ display: 'block', padding: '30px 0' }}>
          Votre rendez-vous
          <br />
          Live Shopping.
        </h1>
        {event && (
          <div>
            <div className={classes.eventCard}>
              <div className="header" style={{ display: 'inline-block', padding: 20 }}>
                <div className="calendarIconXL">
                  <FcCalendar size={50} />
                </div>
                <div className="calendarDate">
                  <div className="calendarMoment">
                    {moment(event.eventDate)
                      .tz(timezone)
                      .format('dddd, Do MMMM YYYY à HH:mm')}
                  </div>
                  {/*<div className="calendarMoment">à {moment(event.eventDate).tz(timezone).format('HH:mm')}</div>*/}
                  <div style={{ alignItems: 'center', padding: '3px 0 0px 0', display: 'flex', color: '#777' }}>
                    <RiTimeLine size={13} />
                    <div style={{ paddingLeft: 5, fontSize: 12 }}>
                      {timezone} {`(UTC${moment.tz(timezone).format('Z')})`}
                    </div>
                  </div>
                </div>
              </div>
              {result && (
                <div
                  className="eventStatus"
                  style={{ borderRightWidth: 10, borderRightStyle: 'solid', borderRightColor: result['color'] }}
                >
                  <div className="eventStatusLabel" style={{ color: result['color'] }}>
                    {result['label']}
                  </div>
                </div>
              )}

              <div className="content" style={{ borderTop: '1px solid rgba(34,36,38,.1)' }}>
                {event.status.includes('C') && (
                  <div>
                    <Link
                      to={{ pathname: `/join/${event.id}/${event.roomID}/${event.host}` }}
                      onClick={
                        soon
                          ? null
                          : e => {
                              e.preventDefault();
                            }
                      }
                      className={`MuiButtonBase-root MuiButton-root MuiButton-contained videoBtn start ${
                        soon ? '' : 'disabled'
                      }`}
                    >
                      <FcVideoCall size={25} />
                      <span style={{ paddingLeft: 7 }}>
                        {soon ? 'Se connecter' : expired ? 'La cession a expiré' : 'Patience...'}
                      </span>
                    </Link>
                    {!soon && !expired && (
                      <div className="patience">
                        La connexion à votre cession de Live Shopping sera ouverte 5 minutes avant l'heure prévue.
                      </div>
                    )}
                  </div>
                )}

                {averageRating && (
                  <div
                    className="eventAttribute"
                    style={{ paddingTop: 15, paddingBottom: 15, justifyContent: 'center', paddingRight: 35 }}
                  >
                    <Rating name="simple-controlled" value={averageRating} size="large" precision={0.25} readOnly />
                  </div>
                )}

                <div className="eventAttribute">
                  <div className="eventIcon">
                    <BsPersonBoundingBox size={20} />
                  </div>
                  <div className="eventDesc">
                    Rendez-vous avec {sponsor} {invite}
                  </div>
                </div>

                <div className="eventAttribute">
                  <div className="eventIcon">
                    <RiTimerLine size={20} />
                  </div>
                  <div className="eventDesc">{event.vendor.items[0].maxduration} minutes</div>
                </div>

                <div className="eventAttribute">
                  <div className="eventIcon">
                    <FaYoutube size={20} />
                  </div>
                  <div className="eventDesc">{event.roomID}</div>
                </div>

                {event.description && (
                  <div className="eventAttribute">
                    <div className="eventIcon">
                      <RiInformationLine size={22} />
                    </div>
                    <div className="eventDesc">{event.description}</div>
                  </div>
                )}

                {event.details.items.length > 0 && (
                  <div className="eventAttribute">
                    <div className="eventIcon">
                      <RiHistoryFill size={20} />
                    </div>
                    <Button color="primary" onClick={() => this.setState({ showModal: !this.state.showModal })}>
                      Historique des connections
                    </Button>
                    <Dialog
                      open={this.state.showModal}
                      onClose={() => this.setState({ showModal: !this.state.showModal })}
                      className="HistoryModal"
                    >
                      <IconButton
                        aria-label="close"
                        style={{ width: 50, height: 50, position: 'absolute', right: 20 }}
                        onClick={() => this.setState({ showModal: false })}
                      >
                        <CloseIcon />
                      </IconButton>
                      <DialogTitle>Historique des connexions</DialogTitle>
                      <Divider />
                      <DialogContent>
                        {event.details.items
                          .sort((a, b) => b.start.localeCompare(a.start))
                          .map((detail, i) => {
                            return (
                              <div style={{ paddingTop: 10 }} key={i}>
                                {detail.user} : {moment(detail.start).format('DD/MM')} de{' '}
                                {moment(detail.start)
                                  .tz(timezone)
                                  .format('HH:mm:ss')}{' '}
                                à{' '}
                                {moment(detail.end)
                                  .tz(timezone)
                                  .format('HH:mm:ss')}
                              </div>
                            );
                          })}
                      </DialogContent>
                    </Dialog>
                  </div>
                )}
              </div>
              {event.status.includes('C') && !expired && (
                <button
                  className="ui bottom attached button"
                  style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                  onClick={this.handleChangeStatus.bind(this, event, 'Z1')}
                >
                  <IoMdCloseCircle />
                  &nbsp;Annuler
                </button>
              )}
              {event.status.includes('A') && !expired && (
                <button
                  className="ui bottom attached button"
                  style={{
                    backgroundColor: '#00a108',
                    color: '#fff',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                  onClick={this.handleChangeStatus.bind(this, event, 'C1')}
                >
                  <IoIosCheckmarkCircle />
                  &nbsp;Valider
                </button>
              )}
              {event.status.includes('Z') && !expired && (
                <button
                  className="ui bottom attached button"
                  style={{
                    backgroundColor: '#00a108',
                    color: '#fff',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                  onClick={this.handleChangeStatus.bind(this, event, 'C3')}
                >
                  <IoIosCheckmarkCircle />
                  &nbsp;Rétablir
                </button>
              )}
            </div>

            <div className={classes.eventCard} style={{ flex: 1, marginLeft: 20, padding: '20px 30px', clear: 'none' }}>
              <EventProducts
                vendorID={event.vendorID}
                eventId={event.id}
                products={event.products}
                website={event.vendor.items[0].website}
              />
              <EventComments
                eventId={event.id}
                comments={event.comments}
                user={event.host}
                name={event.team.items[0].username}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const ViewEventWithData = withApollo(
  compose(
    graphql(QueryGetEvent, {
      options: ({
        match: {
          params: { id },
        },
      }) => ({
        variables: { id },
        fetchPolicy: 'network-only',
      }),
      props: ({ data: { getEvent: event, loading } }) => ({
        event,
        loading,
      }),
    }),
    graphql(MutationUpdateEventStatus, {
      options: ({
        match: {
          params: { id },
        },
      }) => {
        return {
          refetchQueries: [
            {
              query: QueryGetEvent,
              variables: { id },
            },
          ],
        };
      },
      props: props => ({
        updateEvent: (event, newstatus) => {
          return props.mutate({
            variables: { id: event.id, status: newstatus },
            optimisticResponse: () => ({
              updateEvent: {
                ...event,
                __typename: 'Event',
                ratings: { __typename: 'RatingConnection', items: [] },
                comments: { __typename: 'CommentConnection', items: [] },
              },
            }),
          });
        },
      }),
    })
  )(ViewEvent)
);

export default RoomState(withStyles(styles)(ViewEventWithData));
