import React from 'react';
import PropTypes from 'prop-types';

import Amplify, { API, Auth } from 'aws-amplify';

import { IoMdContact } from 'react-icons/io';
import { ImPlay } from 'react-icons/im';
import { FcCalendar, FcVideoCall } from 'react-icons/fc';

import { Link } from 'react-router-dom';

import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

import { Button, Dialog } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { makeStyles } from '@material-ui/core/styles';

import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

const useStyles = makeStyles(theme => ({
  input: {
    border: '1px solid #d2d2d2',
    padding: '0px 15px',
    borderRadius: 5,
    '& .MuiTextField-root': {
      margin: 0,
      border: 0,
    },
  },
  btn: {
    backgroundColor: '#ff6600',
    color: '#fff',
    marginLeft: 15,
    '&:hover': {
      color: '#666',
    },
  },
  btnCancel: {
    backgroundColor: '#dD2d2D2',
    color: '#777',
    '&:hover': {
      color: '#666',
    },
  },
}));

export default function Signin(props) {
  const classes = useStyles();
  const [authState, setAuthState] = React.useState();
  const [error, setError] = React.useState();
  const [visio, setVisio] = React.useState({
    username: '',
    roomID: '',
    eventID: '',
  });

  const [disabled, setDisabled] = React.useState(true);

  const handleChange = event => {
    const { target } = event;
    const { name, value } = target;
    event.persist();
    setVisio({
      ...visio,
      [name]: value,
    });
    if (visio.username.length > 5 && visio.roomID.length > 10) {
      setDisabled(false);
    }
    setError('');
  };

  return (
    <div className="ui container raised very padded segment">
      <h1 className="pageTitle" style={{ display: 'block', padding: '30px' }}>
        Connectez-vous
        <br />
        à une room <br />
        Live Shopping.
      </h1>
      <div style={{ maxWidth: 400, border: '1px solid #d2d2d2', padding: 50, margin: '30px 20px' }}>
        <div className="field eight wide" style={{ marginTop: 20 }}>
          <FormControl className={(classes.margin, classes.textField)} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-email">Votre nom d'utilisateur</InputLabel>
            <OutlinedInput
              name="username"
              type="text"
              value={visio.username}
              onChange={handleChange}
              startAdornment={
                <InputAdornment position="start">
                  <IoMdContact size={20} color={'#999'} />
                </InputAdornment>
              }
              labelWidth={200}
            />
          </FormControl>
        </div>

        <div className="field eight wide" style={{ marginTop: 20 }}>
          <FormControl className={(classes.margin, classes.textField)} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-email">La room Live</InputLabel>
            <OutlinedInput
              name="roomID"
              type="text"
              value={visio.roomID}
              onChange={handleChange}
              startAdornment={
                <InputAdornment position="start">
                  <ImPlay size={20} color={'#999'} />
                </InputAdornment>
              }
              labelWidth={90}
            />
          </FormControl>
        </div>
        <div style={{ padding: 20, color: 'red', fontWeight: 'bold', maxWidth: 400 }}>{error}</div>
        <Link
          to={{ pathname: `/join/${visio.id}/${visio.roomID}/${visio.username}` }}
          onClick={
            disabled
              ? e => {
                  e.preventDefault();
                }
              : null
          }
          className={`MuiButtonBase-root MuiButton-root MuiButton-contained videoBtn start ${
            disabled ? 'disabled' : ''
          }`}
        >
          <FcVideoCall size={25} />
          <span style={{ paddingLeft: 7 }}>Se connecter</span>
        </Link>
      </div>
    </div>
  );
}
