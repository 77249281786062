import React, { PureComponent } from 'react';
import {
  BarChart,
  Bar,
  ComposedChart,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Sector,
  ResponsiveContainer,
} from 'recharts';
import rockset from '@rockset/client';
import { withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { HiOutlineDotsVertical } from 'react-icons/hi';

import { Auth } from 'aws-amplify';

import moment from 'moment';
import 'moment/locale/fr';
import FlexApi from 'twilio/lib/rest/FlexApi';
import { FaGlasses, FaHourglassEnd, FaCloudShowersHeavy } from 'react-icons/fa';
moment.locale('fr');

function getVendor() {
  let vendorID = Auth.user.attributes['custom:vendorID'];
  return vendorID;
}

const rocksetClient = rockset('Qw8VCNr1bMcf6odToktihRpHCuoZIR5nyO5VNfWtrhWKp9DKksCWQic4DCu7YJeO');

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const styles = theme => ({
  card: {
    padding: '20px 30px 40px 0',
    margin: 10,
    height: 300,
    width: '100%',
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: 5,
    backgroundColor: '#fff',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  XScard: {
    padding: 20,
    margin: 10,
    height: 140,
    width: '100%',
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: 5,
    backgroundColor: '#fff',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  title: {
    fontFamily: 'ProximaNova, sans-serif',
    fontWeight: 'bold',
    padding: '5px 0 10px 45px',
    fontSize: 16,
  },
  XStitle: {
    fontFamily: 'ProximaNova, sans-serif',
    fontWeight: 'bold',
    padding: '5px 0 10px 20px',
    fontSize: 16,
  },
  bigNumbers: {
    fontFamily: 'ProximaNova, sans-serif',
    fontWeight: 'bold',
    padding: '0px 0px 0px 20px',
    fontSize: 36,
  },
  Range: {
    backgroundColor: '#fff',
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: 5,
    padding: 5,
    margin: 10,
    minWidth: 250,
    textAlign: 'right',
    alignItems: 'center',
  },
});

const range_options = [
  {
    title: 'Ce mois ci',
    start: moment()
      .startOf('month')
      .format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
  },
  {
    title: 'Le mois dernier',
    start: moment()
      .subtract(1, 'months')
      .startOf('month')
      .format('YYYY-MM-DD'),
    end: moment()
      .subtract(1, 'months')
      .endOf('month')
      .format('YYYY-MM-DD'),
  },
  {
    title: 'Cette année',
    start: moment()
      .startOf('year')
      .format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
  },
  {
    title: "L'année dernière",
    start: moment()
      .subtract(1, 'year')
      .startOf('year')
      .format('YYYY-MM-DD'),
    end: moment()
      .subtract(1, 'year')
      .endOf('year')
      .format('YYYY-MM-DD'),
  },
  {
    title: 'Les 12 derniers mois',
    start: moment()
      .subtract(1, 'year')
      .format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
  },
  { title: 'Toute la période', start: '2020-01-01', end: moment().format('YYYY-MM-DD') },
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const renderCustomAxisTick = props => {
  const { x, y, payload } = props;
  return (
    <text
      x={x}
      y={y}
      style={{
        textAnchor: 'start',
        width: 50,
        height: 'auto',
        flexDirection: 'row',
        flex: 1,
        flexWrap: 'wrap',
        fontSize: 10,
        display: 'block',
      }}
    >
      {payload.value}
    </text>
  );
};

const renderActiveShape = props => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, total } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill} style={{ fontSize: 30, fontWeight: 'bold' }}>
        {payload.rating}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`Note ${payload.rating}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    </g>
  );
};

class Analytics extends PureComponent {
  state = {
    activeIndex: 0,
    start: '2020-01-01',
    end: moment().format('YYYY-MM-DD'),
    params: [
      {
        name: 'vendorID',
        type: 'string',
        value: getVendor(),
      },
      {
        name: 'startDate',
        type: 'date',
        value: '2020-01-01',
      },
      {
        name: 'endDate',
        type: 'date',
        value: moment().format('YYYY-MM-DD'),
      },
    ],
    dataEvents: [],
    dataEventsbyhost: [],
    dataRatings: [],
    dataProducts: [],
    range: [],
    hosts: [],
    status: [],
    ratings: [1, 2, 3, 4, 5],
    totalEvents: 0,
    averageRating: 0,
    anchorEl: null,
    open: false,
  };

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index,
    });
  };

  handleClick(event) {
    this.setAnchorEl(event.currentTarget);
  }

  setAnchorEl(value) {
    this.setState({
      anchorEl: value,
      open: !this.state.open,
    });
  }

  handleClose() {
    this.setAnchorEl(null);
  }

  handleMenu(item) {
    const result = range_options.find(data => data.title === item.title);
    this.setState({
      start: result['start'],
      end: result['end'],
    });
    const params = [
      {
        name: 'vendorID',
        type: 'string',
        value: getVendor(),
      },
      {
        name: 'startDate',
        type: 'date',
        value: result['start'],
      },
      {
        name: 'endDate',
        type: 'date',
        value: result['end'],
      },
    ];
    this.handleClose();
    this.fetchQueries(params);
  }

  componentDidMount() {
    this.fetchQueries(this.state.params);
  }

  fetchQueries(params) {
    const { start, end } = this.state;
    const diff = moment.duration(moment(end).diff(moment(start)));
    var diff_months = parseInt(diff.asMonths());

    var range = [];
    for (let i = 0; i < diff_months + 1; i++) {
      let a = {};
      a[
        moment(start)
          .add(i, 'months')
          .format('YYYY-MM-DD')
      ] = 0;
      range.push(
        moment(start)
          .add(i, 'months')
          .format('YYYY-MM-DD')
      );
    }
    this.setState({ range: range });

    rocksetClient.queryLambdas
      .executeQueryLambda('commons', 'ratings', 'edb6866f37fccebd', {
        parameters: [
          ...params,
          {
            name: 'status',
            type: 'string',
            value: 'C1',
          },
        ],
      })
      .then(response => {
        this.setState({ dataRatings: response.results });
      })
      .catch(console.error);

    setTimeout(() => {
      rocksetClient.queryLambdas
        .executeQueryLambda('commons', 'events', '24d6866953c80e08', {
          parameters: [
            ...params,
            {
              name: 'status',
              type: 'string',
              value: 'C1',
            },
          ],
        })
        .then(response => {
          this.setState({ dataEvents: response.results });
        })
        .catch(console.error);
    }, 300);

    setTimeout(() => {
      rocksetClient.queryLambdas
        .executeQueryLambda('commons', 'eventsbyhost', 'd5237b24b31a9a5f', {
          parameters: [
            ...params,
            {
              name: 'status',
              type: 'string',
              value: 'C1',
            },
          ],
        })
        .then(response => {
          let data = [];
          let hosts = [];
          response.results.map(item => {
            var array = { month: item.month, ...item['host'] };
            data.push(array);
            hosts.push(...Object.keys(item['host']));
          });
          var uniq = [...new Set(hosts)];
          console.log(data, uniq);
          this.setState({
            dataEventsbyhost: data,
            hosts: uniq,
          });
        })
        .catch(console.error);
    }, 500);

    setTimeout(() => {
      rocksetClient.queryLambdas
        .executeQueryLambda('commons', 'totalEvents', '7707dfb43ea4e640', {
          parameters: [
            ...params,
            {
              name: 'status',
              type: 'string',
              value: 'C1',
            },
          ],
        })
        .then(response => {
          this.setState({ totalEvents: response.results[0].TOTAL });
        });
    }, 800);

    setTimeout(() => {
      rocksetClient.queryLambdas
        .executeQueryLambda('commons', 'AverageRating', 'aa212629f9db05a9', {
          parameters: [
            ...params,
            {
              name: 'status',
              type: 'string',
              value: 'C1',
            },
          ],
        })
        .then(response => {
          this.setState({ averageRating: response.results[0].rating });
        });
    }, 1000);

    setTimeout(() => {
      rocksetClient.queryLambdas
        .executeQueryLambda('commons', 'TopProducts', '8c9b1189044b1e80', {
          parameters: params,
        })
        .then(response => {
          this.setState({ dataProducts: response.results });
        });
    }, 1200);
  }

  render() {
    const {
      dataEvents,
      dataEventsbyhost,
      dataRatings,
      dataProducts,
      totalEvents,
      averageRating,
      start,
      end,
      range,
      hosts,
    } = this.state;
    const { classes } = this.props;

    return (
      <div className="ui container raised very padded segment" style={{ backgroundColor: '#f2f2f2' }}>
        <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
          <h1 className="pageTitle" style={{ display: 'block', padding: '30px' }}>
            Live
            <br />
            Analytics.
          </h1>
          <div className={classes.Range}>
            {moment(start).format('DD-MM-YYYY')} - {moment(end).format('DD-MM-YYYY')}
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={e => this.handleClick(e)}
            >
              <HiOutlineDotsVertical color={'#ff6600'} />
            </IconButton>
          </div>
          <Menu
            id="range-menu"
            anchorEl={this.state.anchorEl}
            keepMounted
            open={this.state.open}
            onClose={() => this.handleClose()}
          >
            {range_options.map((item, index) => (
              <MenuItem key={index} onClick={() => this.handleMenu(item)}>
                {item.title}
              </MenuItem>
            ))}
          </Menu>
        </div>

        <div className="ui form" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className={classes.XScard}>
            <div className={classes.XStitle}>Total Live Shopping</div>
            <div className={classes.bigNumbers}>{totalEvents}</div>
          </div>

          <div className={classes.XScard}>
            <div className={classes.XStitle}>Revenu</div>
            <div className={classes.bigNumbers}>5 600€</div>
          </div>

          <div className={classes.XScard}>
            <div className={classes.XStitle}>Satisfaction</div>
            <div className={classes.bigNumbers} style={{ display: 'flex', alignItems: 'center', paddingTop: 10 }}>
              <Rating
                name="simple-controlled"
                value={averageRating ? averageRating : 0}
                size="large"
                precision={0.25}
                readOnly
              />
              <span style={{ paddingLeft: 10, fontSize: 15 }}>
                {averageRating ? `( ${averageRating.toFixed(1)} )` : ''}
              </span>
            </div>
          </div>
        </div>

        <div className="ui form" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className={classes.card}>
            <div className={classes.title}>Nombre de Live Shopping</div>
            <ResponsiveContainer>
              <BarChart data={dataEvents} title={'Evolution du nombre de Live Shopping'}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="DATE" />
                <YAxis />
                <Tooltip />
                {/*
            <Legend />
           */}
                <Bar dataKey={'TOTAL'} fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </div>

          <div className={classes.card}>
            <div className={classes.title}>Live Shopping par vendeur</div>
            <ResponsiveContainer>
              <BarChart data={dataEventsbyhost} title={'Live Shopping'}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Legend />
                {hosts.map((host, index) => (
                  <Bar key={index} dataKey={host} stackId="a" fill={COLORS[index % COLORS.length]} />
                ))}

                <Tooltip />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="ui form" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className={classes.card}>
            <div className={classes.title}>Top produits</div>
            <ResponsiveContainer>
              <ComposedChart
                layout="vertical"
                data={dataProducts}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
              >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis type="number" />
                <YAxis dataKey="name" style={{ fontSize: 11, paddingRight: 3 }} type="category" />
                <Bar dataKey="total" fill="#8884d8" />
              </ComposedChart>
            </ResponsiveContainer>
          </div>

          <div className={classes.card}>
            <div className={classes.title}>Satisfaction Live Shopping</div>
            <ResponsiveContainer className="container" height={250} width="100%">
              <PieChart>
                <Pie
                  activeIndex={this.state.activeIndex}
                  activeShape={renderActiveShape}
                  data={dataRatings}
                  cx="50%"
                  cy="50%"
                  outerRadius="80%"
                  innerRadius="50%"
                  dataKey="value"
                  fill="#8884d8"
                  dataKey="total"
                  onMouseEnter={this.onPieEnter}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Analytics);
