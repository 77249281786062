import gql from 'graphql-tag';

export default gql(`
query ($vendorID: String) {
  listEvents(filter:{
    and: {
    vendorID:{eq: $vendorID},
    status:{notContains:"Z"} 
    }
  }) {
    items {
      id
      host
      guestName
      guestEmail
      location
      eventDate
      status
      description
      roomID
      vendorID
    }
  }
}`);
