import gql from 'graphql-tag';

export default gql(`
query ($email:String!) {
  listEvents (filter:  {
     host: {eq: $email }
  }) {
    items {
      id
      host
      guestName
      guestEmail
      location
      eventDate
      status
      description
      roomID
      ratings(limit: 1000) {
        items {
          rating
          createdAt
          user
        }
      }
      guests(limit: 1000) {
        items {
          name
          email
          sponsor
          onboard
        }
      }
      comments {
        items {
          commentId
        }
      }
    }
  }
}`);
