import gql from 'graphql-tag';

export default gql(`
subscription($eventId: String) {
    onUpdateProduct(eventId:$eventId) 
  {
    id
    eventId
    name
    url
    image
  }
}`);
