import gql from 'graphql-tag';

export default gql(`
query ($vendorID: String) {
  listEvents (filter:  {
    vendorID: {eq: $vendorID }
  }) {
    items {
      id
      host
      guestName
      guestEmail
      location
      eventDate
      status
      description
      roomID
      vendorID
      ratings(limit: 1000) {
        items {
          rating
          createdAt
          user
        }
      }
      guests(limit: 1000) {
        items {
          name
          email
          sponsor
          onboard
        }
      }
      comments {
        items {
          commentId
        }
      }
    }
  }
}`);
