import gql from 'graphql-tag';

export default gql(`
mutation createVideo($eventId: String!, $vendorID: String, $user: String, $start: String, $end: String, $roomID: String){
    createVideo (
    input: {
    eventId: $eventId
    vendorID: $vendorID
    user: $user
    start: $start
    end: $end
    roomID: $roomID
}
) {
  eventId
    vendorID
    user
    start
    end
    roomID
  }
}`);
