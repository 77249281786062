import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Auth } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { graphql, compose, withApollo } from 'react-apollo';
import _ from 'lodash';
import QueryGetCalendar from '../graphql/QueryGetCalendar';
import MutationCreateCalendar from '../graphql/MutationCreateCalendar';
import MutationDeleteCalendar from '../graphql/MutationDeleteCalendar';
import MutationUpdateCalendar from '../graphql/MutationUpdateCalendar';

import { RiTimeLine } from 'react-icons/ri';
import { IoMdClose, IoIosArrowForward } from 'react-icons/io';
import { MdAddCircleOutline } from 'react-icons/md';
import { BiCalendarEdit } from 'react-icons/bi';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import CircularProgress from '@material-ui/core/CircularProgress';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import { Divider, Dialog, Button, DialogTitle, DialogActions, TextField } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';

import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

import QueryGetMember from '../graphql/QueryGetMember';
import MutationUpdateMemberStatus from '../graphql/MutationUpdateMemberStatus';
import MyTeam from './MyTeam';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/fr';

function getUser() {
  let user = Auth.user.attributes['email'];
  return user;
}

const styles = theme => ({
  calendarBtn: {
    color: '#ff6600',
    fontSize: 12,
    justifyContent: 'flex-start',
    padding: '7px 10px',
    borderRadius: 5,
  },
  selectTmz: {
    width: 200,
    margin: '10px 0 0 10px',
    border: '1px solid #c1c1c1',
    padding: '5px 11px',
    borderRadius: 5,
    zIndex: 9999,
    minHeight: 35,
  },
});

const IOSSwitch = withStyles(theme => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function getSortedWeekDays() {
  var weekDaysSorted = [];
  var currentIndex = 1; //moment().day();
  for (var i = 0; i < 7; i++) {
    weekDaysSorted.push({
      title: moment.weekdays(currentIndex % 7),
      idDay: currentIndex % 7,
    });
    currentIndex += 1;
  }
  return weekDaysSorted;
}

function DisabledHours() {
  const hours = [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23];
  return hours;
}

function RangePicker(props) {
  const splitTime = props.rowTime.split(':');
  const currentTime = moment()
    .hour('' + splitTime[0])
    .minute('' + splitTime[1]);

  const startHour = props.rowStart ? parseInt(props.rowStart.slice(0, 2)) : 7;
  console.log(startHour);

  return (
    <TimePicker
      style={{ width: 100, marginRight: 10 }}
      showSecond={false}
      minuteStep={15}
      name={props.name}
      start={props.start}
      defaultValue={currentTime}
      className="rc-time-picker-profile"
      disabledHours={() => {
        const hours = [21, 22, 23];
        for (var i = 0; i < startHour + 1; i++) {
          hours.push(i);
        }
        return hours;
      }}
      key={_.uniqueId()}
      onChange={props.handleTimeChange.bind(this, props.start)}
    />
  );
}

const RowTimePicker = withStyles(theme => ({
  delBtn: {
    padding: 3,
    margin: 3,
    color: '#777',
    minWidth: '20px !important',
  },
}))(({ classes, ...props }) => {
  const startTime = props.rowTime.start.split(':');
  const start = moment()
    .hour('' + startTime[0])
    .minute('' + startTime[1]);

  const hours = [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23];
  for (var i = 7; i < parseInt(startTime[0]) + 1; i++) {
    hours.push(i);
  }

  const endTime = props.rowTime.end.split(':');
  const end = moment()
    .hour('' + endTime[0])
    .minute('' + endTime[1]);

  return (
    <div>
      <ul className="list-schedule" style={{ padding: '5px 0 0 0', minWidth: 280, margin: 0 }}>
        <li id={props.id} style={{ padding: 0, margin: 0, listStyle: 'none' }}>
          <span style={{ display: 'inline-block' }}>
            <TimePicker
              style={{ width: 100, marginRight: 10 }}
              showSecond={false}
              minuteStep={15}
              name={'start'}
              start={true}
              defaultValue={start}
              className="rc-time-picker-profile"
              disabledHours={DisabledHours}
              key={_.uniqueId()}
              onChange={e => {
                for (var i = 7; i < parseInt(moment(e).format('H')) + 1; i++) {
                  hours.push(i);
                }
                props.handleTimeChange(true, e);
              }}
            />
            <TimePicker
              style={{ width: 100, marginRight: 10 }}
              showSecond={false}
              minuteStep={15}
              name={'end'}
              start={false}
              defaultValue={end}
              className="rc-time-picker-profile"
              disabledHours={() => {
                return hours;
              }}
              key={_.uniqueId()}
              onChange={e => {
                props.handleTimeChange(false, e);
              }}
            />

            <Button onClick={props.handleDeleteClick} className={classes.delBtn}>
              <IoMdClose size={15} />
            </Button>
          </span>
        </li>
      </ul>
      <div></div>
    </div>
  );
});

class Calendar extends React.Component {
  render() {
    const { weekdays } = this.props;
    let rowsByDays = this.props.rowsbydays;
    let calendar = [];
    weekdays.map((day, index) =>
      calendar.push(
        <li
          key={index}
          idday={day.idDay}
          style={{ display: 'flex', clear: 'both', paddingTop: 7, alignItems: 'flex-start', height: 'auto' }}
        >
          <span style={{ width: 100, display: 'inline-block', float: 'left', paddingTop: 10 }}>{day.title}</span>
          <span style={{ width: 135, display: 'inline-block', float: 'left' }}>
            <FormControlLabel
              control={
                <Switch
                  checked={rowsByDays[index].isOpen}
                  onChange={this.props.handleSelectChange.bind(this, index)}
                  name="checked"
                  color="primary"
                />
              }
              label={rowsByDays[index].isOpen ? 'Disponible' : 'Indisponible'}
              style={{ color: rowsByDays[index].isOpen ? 'primary' : '#666', fontSize: 11 }}
            />
          </span>
          <div style={{ width: 280, float: 'left' }}>
            {rowsByDays[index].isOpen ? rowsByDays[index].rows.map((row, index) => row) : ''}
          </div>
          {rowsByDays[index].isOpen ? (
            <div style={{ float: 'left', paddingTop: 8 }}>
              <Button
                onClick={() => this.props.handleAddClick(index)}
                style={{ color: '#ff6600', fontSize: 12, justifyContent: 'flex-start', padding: 0 }}
              >
                <MdAddCircleOutline size={20} color="#ff6600" />
                &nbsp;Ajouter
              </Button>
            </div>
          ) : (
            ''
          )}
        </li>
      )
    );
    return calendar;
  }
}

class SimpleCalendar extends React.Component {
  render() {
    const { weekdays } = this.props;
    let rowsByDays = this.props.rowsbydays;
    let calendar = [];
    weekdays.map((day, index) => {
      calendar.push(
        <li key={index} idday={day.idDay} style={{ display: 'flex', clear: 'both', paddingTop: 7 }}>
          <span style={{ width: 120, display: 'inline-block', float: 'left', textTransform: 'capitalize' }}>
            {day.title}
          </span>
          <div style={{ width: 200, float: 'left', display: 'inline-block' }}>
            {rowsByDays[index].isOpen ? rowsByDays[index].rows.map((row, index) => row) : 'Indisponible'}
          </div>
        </li>
      );
    });
    return calendar;
  }
}

class ViewProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timezone: moment.tz.guess(),
      rowsByDays: this.init(props.calendar),
      rowsByDaysReadonly: this.initRead(props.calendar),
      needUpdate: false,
      showModal: false,
      loading: false,
    };
    this.initialState = { ...this.state };
  }

  handleChange(field, { target: { value } }) {
    const { member } = this.state;
    member[field] = value;
    this.setState({ member });
  }

  handleTMZ = event => {
    this.setState({ timezone: event.target.value });
  };

  handleTimeChange = (key, idDay, index, indexSub, id, title) => async (isStart, e) => {
    const value = e;
    if (this.state.rowsByDays[index].rows[indexSub] == undefined) {
      indexSub = this.state.rowsByDays[index].rows.length - 1;
    }
    if (value != null) {
      isStart
        ? (this.state.rowsByDays[index].rows[indexSub].props.rowTime.start = value.format('HH:mm'))
        : (this.state.rowsByDays[index].rows[indexSub].props.rowTime.end = value.format('HH:mm'));
    }
  };

  handleSave = async () => {
    const { timezone, rowsByDays } = this.state;
    const offSet = parseInt(moment.tz(timezone).format('Z'));
    this.setState({ loading: true });
    rowsByDays &&
      rowsByDays.forEach((day, i) => {
        day.rows &&
          day.rows.forEach(async (row, index) => {
            const start = row.props.rowTime.start;
            const end = row.props.rowTime.end;

            const splitStart = start.split(':');
            const splitEnd = end.split(':');

            const startHourUTC = parseInt(splitStart[0]) - offSet; // calcul UTC
            const endHourUTC = parseInt(splitEnd[0]) - offSet;

            const newStart = ('0' + startHourUTC).slice(-2);
            const newEnd = ('0' + endHourUTC).slice(-2);

            if (start != '00:00' && end != '00:00') {
              const newSchedule = {
                email: this.props.member.email,
                day: this.props.weekDays[i].title,
                start: newStart.concat(':', splitStart[1]),
                end: newEnd.concat(':', splitEnd[1]),
                isOpen: true,
                idDay: row.props.idday,
                vendorID: this.props.member.vendorID,
              };
              if (row.props.id != null) {
                console.log('update');
                const { updateCalendarItem } = this.props;
                newSchedule.id = row.props.id;
                await updateCalendarItem(newSchedule);
              } else {
                console.log('insert');
                const { addCalendarItem } = this.props;
                await addCalendarItem(newSchedule);
              }
            }
          });
      });
    await this.refreshCalendar();
  };

  refreshCalendar = () => {
    const email = getUser();
    const { client } = this.props;
    setTimeout(async () => {
      const newcal = await client.query({
        query: QueryGetCalendar,
        variables: { email: email },
        fetchPolicy: 'network-only',
      });
      this.setState({
        rowsByDaysReadonly: this.initRead(newcal.data.listCalendars.items),
        rowsByDays: this.init(newcal.data.listCalendars.items),
        showModal: false,
        loading: false,
      });
    }, 2000);
  };

  handleDeleteClick = async (i, key, id) => {
    const { rowsByDays } = this.state;
    rowsByDays[i].rows.map(
      (row, index) => (
        key == row.key && rowsByDays[i].rows.splice(index, 1),
        this.setState({
          rowsByDays: rowsByDays,
        })
      )
    );
    if (id != null) {
      const deleteSchedule = {
        id: id,
      };

      const { deleteCalendarItem } = this.props;
      await deleteCalendarItem(deleteSchedule);
    }
  };

  handleAddClick = i => {
    const rowsByDays = this.state.rowsByDays;
    const key = _.uniqueId();

    rowsByDays[i].rows.push(
      <RowTimePicker
        handleDeleteClick={() => this.handleDeleteClick(i, key, null)}
        handleTimeChange={this.handleTimeChange(
          key,
          this.props.weekDays[i].idDay,
          i,
          rowsByDays[i].rows.length,
          null,
          this.props.weekDays[i].title
        )}
        idday={this.props.weekDays[i].idDay}
        key={key}
        index={rowsByDays[i].rows.length}
        rowTime={{
          start: '08:00',
          end: '18:00',
        }}
        isFirst={false}
      />
    );
    this.setState({
      rowsByDays: rowsByDays,
    });
  };

  initRead(calendar) {
    const timezone = moment.tz.guess();
    const offSet = parseInt(moment.tz(timezone).format('Z'));
    let rowsByDays = [];

    for (let i = 0; i < 7; i++) {
      let rowByDays = {
        idDay: this.props.weekDays[i].idDay,
        rows: [],
        isOpen: false,
      };

      rowsByDays.push(rowByDays);
      let currentIndex = 0;
      calendar &&
        calendar.forEach((row, index) => {
          if (row.idDay == this.props.weekDays[i].idDay) {
            const key = _.uniqueId();
            const splitStart = row.start.split(':');
            const splitEnd = row.end.split(':');
            const startHourUTC = parseInt(splitStart[0]) + offSet;
            const endHourUTC = parseInt(splitEnd[0]) + offSet;
            const newStart = ('0' + startHourUTC).slice(-2);
            const newEnd = ('0' + endHourUTC).slice(-2);

            rowsByDays[i].isOpen = row.isOpen;
            rowsByDays[i].rows.push(
              <div key={key}>
                <span>
                  {newStart.concat(':', splitStart[1])} - {newEnd.concat(':', splitEnd[1])}
                </span>
              </div>
            );
            currentIndex++;
          }
        });
    }
    return rowsByDays;
  }

  init(calendar) {
    const timezone = moment.tz.guess();
    const offSet = parseInt(moment.tz(timezone).format('Z'));
    let rowsByDays = [];
    for (let i = 0; i < 7; i++) {
      let rowByDays = {
        idDay: this.props.weekDays[i].idDay,
        rows: [],
        isOpen: false,
      };

      rowsByDays.push(rowByDays);
      let currentIndex = 0;
      calendar &&
        calendar.forEach((row, index) => {
          if (row.idDay == this.props.weekDays[i].idDay) {
            const splitStart = row.start.split(':');
            const splitEnd = row.end.split(':');
            const startHourUTC = parseInt(splitStart[0]) + offSet;
            const endHourUTC = parseInt(splitEnd[0]) + offSet;
            const newStart = ('0' + startHourUTC).slice(-2);
            const newEnd = ('0' + endHourUTC).slice(-2);
            const key = _.uniqueId();
            rowsByDays[i].isOpen = row.isOpen;
            rowsByDays[i].rows.push(
              <RowTimePicker
                handleDeleteClick={() => this.handleDeleteClick(i, key, row.id)}
                handleTimeChange={this.handleTimeChange(
                  key,
                  this.props.weekDays[i].idDay,
                  i,
                  currentIndex,
                  row.id,
                  this.props.weekDays[i].title
                )}
                idday={this.props.weekDays[i].idDay}
                key={key}
                id={row.id}
                index={currentIndex}
                rowTime={{
                  start: newStart.concat(':', splitStart[1]),
                  end: newEnd.concat(':', splitEnd[1]),
                }}
                isFirst={true}
              />
            );
            currentIndex++;
          }
        });
    }
    return rowsByDays;
  }

  handleSelectChange = () => async index => {
    const { rowsByDays } = this.state;
    const isOpen = !rowsByDays[index].isOpen;
    rowsByDays[index].isOpen = isOpen;

    rowsByDays &&
      rowsByDays.forEach((day, i) => {
        rowsByDays[index] &&
          rowsByDays[index].rows.forEach((row, index) => {
            const newSchedule = {
              isOpen: isOpen,
            };
            if (row.props.id != null) {
              const { updateCalendarItem } = this.props;
              newSchedule.id = row.props.id;
              updateCalendarItem(newSchedule);
            }
          });
      });
    this.setState({
      rowsByDays: rowsByDays,
    });
  };

  static defaultProps = {
    updateStatus: () => null,
  };

  handleChangeDispo = async (member, e) => {
    e.preventDefault();
    const { updateStatus } = this.props;
    const newstatus = {
      email: member.email,
      status: !member.status,
    };
    await updateStatus(newstatus);
    await this.refreshCalendar();
  };

  async componentDidMount() {
    await this.refreshCalendar();
  }

  render() {
    const { member, weekDays, calendar, classes } = this.props;
    const { showModal, rowsByDays, rowsByDaysReadonly, timezone, loading } = this.state;
    return (
      <div>
        <div className="ui container raised very padded segment">
          <h1 className="pageTitle" style={{ display: 'block', padding: '30px' }}>
            Votre profil
            <br />
            utilisateur.
          </h1>
          {member && (
            <div className="content">
              <div className="header" style={{ paddingBottom: 20, fontWeight: '600', fontSize: 15 }}>
                {member.name}
              </div>

              <div className="ui form">
                <div className="field eight wide">
                  <label htmlFor="name">Nom d'utilisateur</label>
                  <input
                    style={{ backgroundColor: '#f2f2f2' }}
                    type="text"
                    id="username"
                    readOnly={true}
                    value={member.username}
                  />
                </div>

                <div className="field eight wide">
                  <label htmlFor="name">Email</label>
                  <input
                    style={{ backgroundColor: '#f2f2f2' }}
                    type="text"
                    id="username"
                    readOnly={true}
                    value={member.email}
                  />
                </div>

                <div className="field eight wide">
                  <label htmlFor="name">Profil utilisateur</label>
                  <input
                    style={{ backgroundColor: '#f2f2f2' }}
                    type="text"
                    id="username"
                    readOnly={true}
                    value={member.profile}
                  />
                </div>

                <div className="field eight wide" style={{ paddingBottom: 30 }}>
                  <label htmlFor="where">Magasin</label>
                  <input
                    type="text"
                    id="location"
                    value={member.location}
                    onChange={this.handleChange.bind(this, 'location')}
                    readOnly={true}
                  />
                </div>

                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={member.status}
                      onChange={this.handleChangeDispo.bind(this, member)}
                      name="checked"
                    />
                  }
                  label={member.status ? 'Je suis actuellement disponible' : 'je suis en ce moment indisponible'}
                />

                {calendar && (
                  <div className="field eight wide" style={{ marginTop: 30, borderTop: '1px solid #d2d2d2' }}>
                    <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                      <h1 className="pageTitle" style={{ display: 'block', padding: '30px 0' }}>
                        Votre agenda.
                      </h1>
                      <Button onClick={() => this.setState({ showModal: true })} className={classes.calendarBtn}>
                        <BiCalendarEdit size={30} color="#ff6600" />
                        &nbsp;Modifier
                      </Button>
                    </div>

                    <SimpleCalendar
                      weekdays={weekDays}
                      //status={member.status}
                      rowsbydays={rowsByDaysReadonly}
                      calendar={calendar}
                    />

                    <div style={{ alignItems: 'center', padding: '30px 0 10px 0', display: 'flex' }}>
                      <RiTimeLine size={15} color="#ff6600" />
                      <div style={{ paddingLeft: 7, fontSize: 13, color: '#666' }}>
                        Local time : {moment.tz(moment(), moment.tz.guess()).format('HH:mm')}{' '}
                        {`(${moment.tz.guess()} UTC${moment.tz(timezone).format('Z')})`}
                      </div>
                    </div>

                    <Dialog
                      open={showModal}
                      onClose={() => this.setState({ showModal: !showModal })}
                      className="Modal"
                      maxWidth={'xl'}
                    >
                      <IconButton
                        aria-label="close"
                        style={{ width: 50, height: 50, position: 'absolute', right: 20 }}
                        onClick={() => this.setState({ showModal: false })}
                      >
                        <CloseIcon />
                      </IconButton>
                      <div style={{ textAlign: 'left', paddingTop: 15, paddingBottom: 15, fontSize: 16 }}>
                        Votre calendrier
                      </div>
                      <Divider />
                      <ul style={{ margin: 10, textTransform: 'capitalize', padding: 0, listStyleType: 'none' }}>
                        <Calendar
                          weekdays={weekDays}
                          //status={member.status}
                          rowsbydays={rowsByDays}
                          handleAddClick={index => this.handleAddClick(index)}
                          handleSelectChange={this.handleSelectChange()}
                          calendar={calendar}
                        />
                      </ul>

                      {/*  change TimeZone
                      <NativeSelect
                        id="timezone"
                        defaultValue={timezone}
                        label="Timezone"
                        className={classes.selectTmz}
                        onChange={e => this.handleTMZ(e)}
                      >
                      { moment.tz.names().map( (item,index) => {
                        return (
                        <option key={index} value={item} >{item}</option>
                        )})
                      }
                     </NativeSelect>
                    */}

                      <DialogActions>
                        <Button
                          onClick={() => this.setState({ showModal: !showModal })}
                          color="primary"
                          variant="outlined"
                        >
                          Annuler
                        </Button>
                        <Button onClick={() => this.handleSave()} disabled={loading} color="primary" variant="outlined">
                          {loading ? <CircularProgress size={22} /> : 'Valider'}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {member && (
          <div>
            {member.profile.includes('admin') && (
              <div className="ui container raised very padded segment" style={{ marginTop: 0 }}>
                <div className="content">
                  <div className="item">
                    <MyTeam vendorID={member.vendorID} maxusers={member.vendor.items[0].maxusers} />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const ViewMemberWithData = withApollo(
  compose(
    graphql(QueryGetMember, {
      options: () => {
        const email = getUser();
        return {
          variables: { email: email },
          fetchPolicy: 'no-cache',
        };
      },
      props: ({ data: { getTeam: member, loading } }) => ({
        member,
        loading,
      }),
    }),
    graphql(QueryGetCalendar, {
      options: () => {
        const email = getUser();
        return {
          variables: { email: email },
          fetchPolicy: 'network-only',
        };
      },
      props: ({ data: { listCalendars = { items: [] } } }) => ({
        calendar: listCalendars.items,
        weekDays: getSortedWeekDays(),
      }),
    }),
    graphql(MutationUpdateMemberStatus, {
      options: () => {
        const email = getUser();
        return {
          refetchQueries: [
            {
              query: QueryGetMember,
              variables: { email: email },
            },
          ],
        };
      },
      props: props => ({
        updateStatus: newstatus =>
          props.mutate({
            variables: newstatus,
          }),
      }),
    }),
    graphql(MutationCreateCalendar, {
      options: () => {
        const email = getUser();
        return {
          refetchQueries: [
            {
              query: QueryGetCalendar,
              variables: { email: email },
            },
          ],
        };
      },
      props: props => ({
        addCalendarItem: newSchedule =>
          props.mutate({
            variables: newSchedule,
          }),
      }),
    }),
    graphql(MutationUpdateCalendar, {
      options: () => {
        const email = getUser();
        return {
          refetchQueries: [
            {
              query: QueryGetCalendar,
              variables: { email: email },
            },
          ],
        };
      },
      props: props => ({
        updateCalendarItem: newSchedule =>
          props.mutate({
            variables: newSchedule,
          }),
      }),
    }),
    graphql(MutationDeleteCalendar, {
      options: () => {
        const email = getUser();
        return {
          refetchQueries: [
            {
              query: QueryGetCalendar,
              variables: { email: email },
            },
          ],
        };
      },
      props: props => ({
        deleteCalendarItem: deleteSchedule =>
          props.mutate({
            variables: deleteSchedule,
          }),
      }),
    })
  )(ViewProfile)
);

export default withStyles(styles)(ViewMemberWithData);
