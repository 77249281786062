import gql from 'graphql-tag';

export default gql(`
query($id: ID!) {
  getEvent(id: $id) {
    id
      host
      guestName
      guestEmail
      location
      eventDate
      status
      description
      roomID
      maxduration
      maxguests
      vendorID
      guests(limit: 1000) {
        items {
          name
          email
          sponsor
          onboard
        }
      }
      ratings {
        items {
          rating
          createdAt
          user
        }
      }
      vendor {
        items {
          maxusers
          maxduration
          name
          website
        }
      }
      details {
        items {
          user
          start
          end
        }
      }
      team {
        items {
          username
          location
        }
      }
      products(limit: 100) {
        items {
          url
          name
          eventId
          image
          id
          createdAt
        }
      }
    comments(limit: 1000) {
      items {
        commentId
        content
        createdAt
        user
        name
      }
    }
  }
}`);
