import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import { graphql, compose, withApollo } from 'react-apollo';

import { Divider } from '@material-ui/core';

import styled from 'styled-components';
import { MdAddCircleOutline } from 'react-icons/md';
import { IoIosCheckmarkCircle, IoIosCloseCircle } from 'react-icons/io';
import { GrUserSettings } from 'react-icons/gr';
import { RiSendPlane2Line } from 'react-icons/ri';

import { useTable, useFilters } from 'react-table';

import QueryListTeam from '../graphql/QueryTeam';

const Styles = styled.div`
  .header {
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.85);
    font-size: 20px;
  }
  td {
    border-bottom: 1px solid #d2d2d2;
  }
  table {
    border-spacing: 0;
    border: 1px solid white;
    th {
      text-align: left;
    }
    ,
    td {
      margin: 0;
      padding: 1rem 2rem 1rem 0;
      border-right: 1px solid white;
      color: #333;
    }
  }
`;
const columns = [
  {
    Header: 'Nom',
    accessor: 'name',
    disableFilters: true,
  },
  {
    Header: 'Username',
    accessor: 'username',
    disableFilters: true,
  },
  {
    Header: 'Profile',
    accessor: 'profile',
    disableFilters: true,
  },
  {
    Header: 'Location',
    accessor: 'location',
    disableFilters: true,
  },
  {
    Header: '',
    accessor: 'status',
    disableFilters: true,
    id: 'status',
    Cell: e =>
      e.value ? <IoIosCheckmarkCircle size={25} color={'green'} /> : <IoIosCloseCircle size={25} color={'red'} />,
  },
  {
    Header: '',
    accessor: 'email',
    id: 'send',
    disableFilters: true,
    Cell: e => (
      <a href={'mailto:' + e.value}>
        <RiSendPlane2Line size={20} color={'rgba(0,0,0,.85)'} />
      </a>
    ),
  },
  {
    Header: '',
    accessor: 'email',
    id: 'more',
    disableFilters: true,
    Cell: e => (
      <Link to={{ pathname: `/member/${e.value}` }}>
        <GrUserSettings size={20} color={'rgba(0,0,0,.85)'} />
      </Link>
    ),
  },
];

// Define a default UI for filtering
function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({ column: { filterValue = [], preFilteredRows, setFilter, id } }) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <input
        value={filterValue[0] || ''}
        type="number"
        onChange={e => {
          const val = e.target.value;
          setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: '70px',
          marginRight: '0.5rem',
        }}
      />
      to
      <input
        value={filterValue[1] || ''}
        type="number"
        onChange={e => {
          const val = e.target.value;
          setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: '70px',
          marginLeft: '00',
        }}
      />
    </div>
  );
}

function Table({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, visibleColumns } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters // useFilters!
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  const firstPageRows = rows.slice(0, 10);

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                  {/* Render the columns filter UI */}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {firstPageRows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />
      <div>Showing the first 20 results of {rows.length} rows</div>
      <div></div>
    </>
  );
}

class AllEvents extends Component {
  state = {
    busy: false,
  };
  render() {
    const { busy } = this.state;
    const { teams } = this.props;
    const { maxusers } = this.props;
    return (
      <Styles>
        <div>
          <Divider />
          <div className="team" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <h1 className="pageTitle" style={{ display: 'block', padding: '30px 0' }}>
              Votre équipe.
            </h1>
            {maxusers > teams.length && (
              <div className="addEvent">
                <Link to="/newmember" className="addEventBtn" style={{ justifyContent: 'flex-end', display: 'flex' }}>
                  Nouveau membre&nbsp;
                  <MdAddCircleOutline size={50} color="#ff6600" style={{ paddignLeft: 10 }} />
                </Link>
              </div>
            )}
          </div>

          <div>
            <Table columns={columns} data={[].concat(teams).sort((a, b) => a.name.localeCompare(b.name))} />
          </div>
        </div>
      </Styles>
    );
  }
}

export default withApollo(
  compose(
    graphql(QueryListTeam, {
      options: props => {
        const vendorID = props.vendorID;
        return {
          variables: { vendorID: vendorID },
          fetchPolicy: 'cache-and-network',
        };
      },
      props: ({ data: { listTeams = { items: [] } } }) => ({
        teams: listTeams.items,
      }),
    })
  )(AllEvents)
);
