import gql from 'graphql-tag';

export default gql(`
mutation deleteCalendar($id: ID!) {
    deleteCalendar(input: {
      id: $id
    }
  ) {
      id
      email
      day
      end
      start
      isOpen
      idDay
      vendorID
    }
  }
`);
