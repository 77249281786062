import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Router, browserHistory } from 'react-router';

import { v4 as uuid } from 'uuid';
import { graphql, compose, withApollo } from 'react-apollo';

import QueryAllEvents from '../graphql/QueryAllEvents';
import QueryGetEvent from '../graphql/QueryGetEvent';
import QueryGetMember from '../graphql/QueryGetMember';
import MutationCreateEvent from '../graphql/MutationCreateEvent';

import DatePicker from 'react-datepicker';
import moment from 'moment';

import { nearest15min } from '../utils/Utils';
import DateTimePickerCustomInput from './DateTimePickerCustomInput';

import NewEventProducts from './NewEventProducts';

import { Auth } from 'aws-amplify';
import { RiNeteaseCloudMusicLine } from 'react-icons/ri';

function getUser() {
  let user = Auth.user.attributes['email'];
  return user;
}

const keyId = uuid();

class NewEvent extends Component {
  static defaultProps = {
    createEvent: () => null,
  };

  state = {
    event: {
      id: keyId,
      guestName: '',
      guestEmail: '',
      eventDate: nearest15min().format(),
      description: '',
      createdAt: moment.utc().format(),
      status: 'C1',
      products: [],
    },
    website: '',
    maxduration: '',
    maxguests: '',
  };

  async componentDidMount() {
    const { client } = this.props;
    const query = QueryGetMember;
    const email = getUser();
    const memberQL = await client.query({
      query,
      variables: { email: email },
      fetchPolicy: 'network-only',
    });
    this.setState({
      host: memberQL.data.getTeam.email,
      vendorID: memberQL.data.getTeam.vendorID,
      location: memberQL.data.getTeam.location,
      website: memberQL.data.getTeam.vendor.items[0].website,
      maxduration: memberQL.data.getTeam.vendor.items[0].maxduration,
      //maxguests: memberQL.data.getTeam.vendor.items[0].maxusers,
    });
  }

  handleChange(field, { target: { value } }) {
    const { event } = this.state;
    event[field] = value;
    this.setState({ event });
  }

  handleDateChange(field, value) {
    this.handleChange(field, { target: { value: value.format() } });
  }

  handleSave = async e => {
    e.stopPropagation();
    e.preventDefault();

    const { createEvent } = this.props;
    const { event } = this.state;

    const validEmail = this.checkEmail(event.guestEmail);
    const validUsername = event.guestName.length > 2 ? true : false;

    if (validEmail && validUsername) {
      const member = {
        host: this.state.host,
        vendorID: this.state.vendorID,
        location: this.state.host,
        maxguests: 1,
        maxduration: this.state.maxduration,
      };
      await createEvent({ ...member, ...event });
      browserHistory.push('/');
      window.location.reload();
    } else {
      const errorMessage = !validEmail ? "Merci d'indiquer un email valide" : "Merci d'indiquer un nom d'invité valide";
      window.confirm(errorMessage);
    }
  };

  checkEmail = email => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emailValid = re.test(email);
    return emailValid;
  };

  render() {
    const { event } = this.state;

    return (
      <div className="ui container raised very padded segment">
        <h1 className="ui header">Nouveau Rendez-vous !</h1>
        <div className="ui form" style={{ paddingTop: 20 }}>
          <div className="field eight wide">
            <label htmlFor="name">Nom de l'invité</label>
            <input
              type="text"
              id="guestName"
              value={event.guestName}
              onChange={this.handleChange.bind(this, 'guestName')}
            />
          </div>
          <div className="field eight wide">
            <label htmlFor="name">Email de l'inivité</label>
            <input
              type="text"
              id="guestEmail"
              value={event.guestEmail}
              onChange={this.handleChange.bind(this, 'guestEmail')}
            />
          </div>
          <div className="field eight wide">
            <label htmlFor="when">Date</label>
            <DatePicker
              className="ui container"
              customInput={<DateTimePickerCustomInput />}
              id="eventDate"
              selected={moment(event.eventDate)}
              onChange={this.handleDateChange.bind(this, 'eventDate')}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              showTimeSelect
              timeFormat="hh:mm a"
              timeIntervals={15}
              dateFormat="LL LT"
            />
          </div>
          {/*
                    <div className="field eight wide">
                        <label htmlFor="where">Lieu du rendez-vous</label>
                        <input type="text" id="location" value={event.location} onChange={this.handleChange.bind(this, 'location')} />
                    </div>
                    */}

          <div className="field eight wide">
            <label htmlFor="when">Complétez votre sélection Live Shopping</label>
            <NewEventProducts eventId={keyId} products={event.products} website={'figaret.com'} />
          </div>

          <div className="field eight wide">
            <label htmlFor="description">Informations complémentaires</label>
            <textarea
              name="description"
              id="description"
              rows="10"
              value={event.description}
              onChange={this.handleChange.bind(this, 'description')}
            ></textarea>
          </div>
          <div className="ui buttons">
            <Link to="/" className="ui button">
              Annuler
            </Link>
            <div className="or"></div>
            <button className="ui positive button" onClick={this.handleSave}>
              Valider
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withApollo(
  compose(
    graphql(MutationCreateEvent, {
      props: props => ({
        createEvent: event => {
          return props.mutate({
            variables: event,
            optimisticResponse: () => ({
              createEvent: {
                ...event,
                __typename: 'Event',
              },
            }),
          });
        },
      }),
    })
  )(NewEvent)
);

/*
export default withApollo(compose(
graphql(
    MutationCreateEvent,
    {
        props: (props) => ({
            createEvent: (event) => {
                return props.mutate({
                    update: (proxy, { data: { createEvent } }) => {
                        // Update QueryAllEvents
                        const query = QueryAllEvents;
                        const data = proxy.readQuery({ query });

                        data.listEvents.items = [...data.listEvents.items.filter(e => e.id !== createEvent.id), createEvent];

                        proxy.writeQuery({ query, data });

                        // Create cache entry for QueryGetEvent
                        const query2 = QueryGetEvent;
                        const variables = { id: createEvent.id };
                        const data2 = { getEvent: { ...createEvent } };

                        proxy.writeQuery({ query: query2, variables, data: data2 });
                    },
                    variables: event,
                    optimisticResponse: () => ({
                        createEvent: {
                            ...event, id: uuid(), __typename: 'Event', comments: { __typename: 'CommentConnection', items: [] }
                        }
                    }),
                })
            }
        })
    }
))(NewEvent));
*/
