/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'eu-west-2',
  aws_cognito_region: 'eu-west-2',
  aws_user_pools_id: 'eu-west-2_y0QgugZz7',
  aws_user_pools_web_client_id: '2a8i081stfm7ugs5kufgtgeflm',
  aws_appsync_graphqlEndpoint: 'https://i6ol2bsounfxbasv3rg2cygwti.appsync-api.eu-west-2.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-2',
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: 'da2-2pv5azv3orfdfomtsvzoeqhz5u',
  aws_identity_pool_id: 'eu-west-2:2235fee5-443d-4c36-8835-12066e1ad62f',
};

export default awsmobile;
