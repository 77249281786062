import gql from 'graphql-tag';

export default gql(`
mutation($id: ID!) {
  deleteEvent(id: $id) {
    id
    guestName
    guestEmail
    host
    location
    status
    description
    vendor {
      items {
        eventId
        url
        createdAt
         image
        name
        id
      }
    }
    comments {
      items {
        commentId
      }
    }
  }
}`);
